export var dateHelper = {
    data: () => ({
        moment: require("jalali-moment"),
        persianNumbers: [
            /۰/g,
            /۱/g,
            /۲/g,
            /۳/g,
            /۴/g,
            /۵/g,
            /۶/g,
            /۷/g,
            /۸/g,
            /۹/g,
        ],
        arabicNumbers: [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
    }),
    methods: {
        calculateDuration(startTime, endTime){
            let duration = {};
            if (startTime !== null){
                let a = this.moment.from(startTime, 'fa', 'YYYY-M-D HH:mm')
                    .format('');

                let dob = new Date(a);
                let dobYear = dob.getYear();
                let dobMonth = dob.getMonth();
                let dobDate = dob.getDate();
                let dobHour = dob.getHours();
                let dobMinute = dob.getMinutes();

                let now = new Date(endTime);
                let currentYear = now.getYear();
                let currentMonth = now.getMonth();
                let currentDate = now.getDate();
                let currentHour = now.getHours();
                let currentMinute = now.getMinutes();

                let yearDur = currentYear - dobYear;

                //get months
                let monthDur = 0
                if (currentMonth >= dobMonth) {
                    monthDur = currentMonth - dobMonth;
                }
                else {
                    yearDur--;
                    monthDur = 12 + currentMonth - dobMonth;
                }
                let dateDur = 0;
                if (currentDate >= dobDate)
                    dateDur = currentDate - dobDate;
                else {
                    monthDur--;
                    dateDur = 31 + currentDate - dobDate;
                    if (monthDur < 0) {
                        monthDur = 11;
                        yearDur--;
                    }
                }
                let hourDur = 0 ;
                if (currentHour >= dobHour){
                    hourDur = currentHour - dobHour;
                }
                else {
                    dateDur--;
                    hourDur = 24 + currentHour - dobHour;
                    if (dateDur < 0){
                        dateDur = 30;
                        monthDur--;
                    }
                    if (monthDur < 0) {
                        monthDur = 11;
                        yearDur--;
                    }
                }

                let minuteDur = 0 ;
                if (currentMinute >= dobMinute){
                    minuteDur = currentMinute - dobMinute;
                }
                else {
                    hourDur--;
                    minuteDur = 60 + currentMinute - dobMinute;
                    if (hourDur < 0) {
                        hourDur = 23;
                        dateDur--;
                    }
                    if (dateDur < 0) {
                        dateDur = 29;
                        monthDur--;
                    }
                    if (monthDur < 0) {
                        monthDur = 11;
                        yearDur--;
                    }
                }
                duration = {
                    years: yearDur,
                    months: monthDur,
                    days: dateDur,
                    hours: hourDur,
                    minutes: minuteDur
                };
            }
            else {
                duration = {
                    years: 0,
                    months: 0,
                    days: 0,
                    hours: 0,
                    minutes: 0
                };
            }
            return duration

        },

        convertSecondToHourAndMinute(time) {
            console.log(time)
            let hours = Math.floor(time / 60 / 60);
            let minutes = Math.floor(time / 60) - (hours * 60);
            let seconds = time % 60;

            console.log(hours,minutes,seconds)
            return{
                hours : hours,
                minutes : minutes,
                seconds : seconds
            }
        },

        jalaliToMiladiDate(date) {
            return this.moment.from(date, "fa", "YYYY/MM/DD");
        },
        miladiToJalaliDate(date) {
            return this.moment(date, "YYYY/MM/DD").locale("fa");
        },
        parseDate(date) {
            return this.moment(date, "jYYYY/jM/jD");
        },
        getMiladiDate(timeStamp = 0 ,seprator="/") {
            let dateInput = new Date(timeStamp * 1000);
            let month = dateInput.getMonth() + 1;
            let day = dateInput.getDate();
            let year = dateInput.getFullYear();
            return (
                this.updateNumber(year) +
                seprator +
                this.updateNumber(month) +
                seprator +
                this.updateNumber(day)
            );
        },
        getMiladiDateAndTime(timeStamp = 0 ,seprator="/") {
            let dateInput = new Date(timeStamp * 1000);
            let month = dateInput.getMonth() + 1;
            let day = dateInput.getDate();
            let year = dateInput.getFullYear();
            console.log(dateInput)
            return (
                this.updateNumber(year) +
                seprator +
                this.updateNumber(month) +
                seprator +
                this.updateNumber(day) +
                ' | '+
                this.updateNumber(dateInput.getHours()) + " : " +  this.updateNumber(dateInput.getMinutes()) + " : " +this.updateNumber(dateInput.getSeconds())
            );
        },
        monthName(i ,locale='fa') {
            switch (i % 12) {
                case 1:
                    if(locale==='fa') {
                        return "فروردین";
                    }else{
                        return "January"
                    }
                case 2:
                    if(locale==='fa'){
                        return "اردیبهشت";
                    }else{
                        return "February "
                    }
                case 3:
                    if(locale==='fa'){
                        return "خرداد";
                    }else{
                        return "March"
                    }
                case 4:
                    if(locale==='fa'){
                        return "تیر";
                    }else{
                        return "April"
                    }
                case 5:
                    if(locale==='fa'){
                        return "مرداد";
                    }else{
                        return "May"
                    }
                case 6:
                    if(locale==='fa'){
                        return "شهریور";
                    }else{
                        return "June"
                    }
                case 7:
                    if(locale==='fa'){
                        return "مهر";
                    }else{
                        return "July"
                    }
                case 8:
                    if(locale==='fa'){
                        return "آبان";
                    }else{
                        return "August"
                    }
                case 9:
                    if(locale==='fa'){
                        return "آذر";
                    }else{
                        return "September"
                    }
                case 10:
                    if(locale==='fa'){
                        return "دی";
                    }else{
                        return "October"
                    }
                case 11:
                    if(locale==='fa'){
                        return "بهمن";
                    }else{
                        return "November"
                    }
                case 0:
                    if(locale==='fa'){
                        return "اسفند";
                    }else{
                        return "December"
                    }
            }
        },
        fromMonthName(name) {
            switch (name) {
                case "فروردین":
                    return 1;
                case "اردیبهشت":
                    return 2;
                case "خرداد":
                    return 3;
                case "تیر":
                    return 4;
                case "مرداد":
                    return 5;
                case "شهریور":
                    return 6;
                case "مهر":
                    return 7;
                case "آبان":
                    return 8;
                case "آذر":
                    return 9;
                case "دی":
                    return 10;
                case "بهمن":
                    return 11;
                case "اسفند":
                    return 12;
            }
        },
        updateNumber(number) {
            if (number < 10) {
                return "0" + number;
            }
            return number;
        },
        toFarsiNumber(n) {
            try {
                const farsiDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
                n = n.toString().split("");
                n = n.map((x) => {
                    if (farsiDigits[x]) {
                        return farsiDigits[x];
                    } else {
                        return x;
                    }
                });
                return n.join("");
            } catch (e) {
                return "";
            }
        },
        toEnglishNumber(str) {
            if (typeof str === "string") {
                for (var i = 0; i < 10; i++) {
                    str = str
                        .replace(this.persianNumbers[i], i)
                        .replace(this.arabicNumbers[i], i);
                }
            }
            return str;
        },
        convertTimeToSuitableTime(time){
            let totalTime = this.getMiladiDate(time)
            let timeArray = totalTime.split("/")
            let month_name = this.monthName(timeArray[1],"en")


            return timeArray[2] +" " + month_name+ " " + timeArray[0]
            // return this.getMiladiDate(time)
        },
        serializerObject(
            yearData,
            monthData,
            dayData,
            hourData,
            minuteData,
            timeData
        ) {
            return {
                year: parseInt(this.toEnglishNumber(yearData)),
                month: parseInt(this.toEnglishNumber(monthData)),
                day: parseInt(this.toEnglishNumber(dayData)),
                hour: parseInt(this.toEnglishNumber(hourData)),
                minute: parseInt(this.toEnglishNumber(minuteData)),
                time: timeData === "قبل از ظهر" ? 1 : 0,
            };
        },
        getValidMonthList() {
            let monthName = [];
            for (let i = 1; i < 13; i++) {
                monthName.push(this.monthName(i));
            }
            return monthName;
        },
        getValidDateList(month, year) {
            month = this.fromMonthName(month);
            year = parseInt(this.toEnglishNumber(year));
            let dateList = [];
            let index = 0;
            if (month < 7) {
                index = 31;
            }
            if (month < 12) {
                index = 30;
            }
            if (month === 12) {
                if (year % 4 === 3) {
                    index = 30;
                } else {
                    index = 29;
                }
            }
            for (let i = 1; i <= index; i++) {
                dateList.push(this.toFarsiNumber(this.updateNumber(i)));
            }
            return dateList;
        },

        getValidYearList() {
            let yearList = [];
            for (let i = 0; i <= 20; i++) {
                yearList.push(this.toFarsiNumber(this.currentYear() + i));
            }
            return yearList;
        },
        getValidHourList() {
            let hourList = [];
            for (let i = 0; i < 12; i++) {
                hourList.push(this.toFarsiNumber(this.updateNumber(i)));
            }
            return hourList;
        },
        getValidMinuteList() {
            let monthList = [];
            for (let i = 0; i < 60; i++) {
                monthList.push(this.toFarsiNumber(this.updateNumber(i)));
            }
            return monthList;
        },
        getValidTimeList() {
            return ["قبل از ظهر", "بعد از ظهر"];
        },
        getNowMiladiDategetNowMiladiDate() {
            let currentTime = new Date();
            let month = currentTime.getMonth() + 1;
            let day = currentTime.getDate();
            let year = currentTime.getFullYear();
            return (
                this.updateNumber(year) +
                "/" +
                this.updateNumber(month) +
                "/" +
                this.updateNumber(day)
            );
        },
        getNowTime() {
            let objectHour = this.currentHour();
            let hour = objectHour.hour;
            let offset = objectHour.offset;
            return {
                year: this.toFarsiNumber(this.currentYear()),
                month: this.monthName(this.currentMonth()),
                date: this.toFarsiNumber(this.updateNumber(this.currentDate())),
                hour: this.toFarsiNumber(this.updateNumber(hour)),
                offset: offset ? "بعد از ظهر" : "قبل از ظهر",
                minute: this.toFarsiNumber(this.updateNumber(this.currentMinute())),
                weekday: new Date().toLocaleString("fa-IR", {weekday: "long"}),
            };
        },
        initializeCurrentDate(timeStamp) {
            if (timeStamp === null) {
                return ''
            } else {
                let inputDate = new Date(0);
                inputDate.setUTCSeconds(timeStamp / 1000);
                let persianInputDate = this.miladiToJalaliDate(
                    this.getMiladiDate(timeStamp / 1000)
                );
                let objectHour =
                    inputDate.getHours() < 12
                        ? {hour: inputDate.getHours(), offset: 0}
                        : {hour: inputDate.getHours(), offset: 1};
                let hour = objectHour.hour;
                let offset = objectHour.offset;
                return {
                    year: this.toFarsiNumber(persianInputDate.jYear()),
                    month: this.monthName(persianInputDate.jMonth() + 1),
                    date: this.toFarsiNumber(this.updateNumber(persianInputDate.jDate())),
                    hour: this.toFarsiNumber(this.updateNumber(hour)),
                    offset: offset ? "بعد از ظهر" : "قبل از ظهر",
                    minute: this.toFarsiNumber(this.updateNumber(inputDate.getMinutes())),
                    second: this.toFarsiNumber(this.updateNumber(inputDate.getSeconds())),
                    weekday: new Date(timeStamp).toLocaleString("fa-IR", {
                        weekday: "long",
                    }),
                };
            }
        },
        momentToTimeStamp(georgiantime,hour,minute,seconds){
            let dateObject = new Date(
                georgiantime._d.getFullYear(),
                georgiantime._d.getMonth(),
                georgiantime._d.getDate(),
                hour,
                minute,
                seconds
            );
            return dateObject.getTime()/1000;
        },
        createTimeStampWithDateInformation(
            yearSelected,
            monthSelected,
            dateSelected,
            hourSelected,
            offsetSelected,
            minuteSelected
        ) {
            let year = parseInt(this.toEnglishNumber(yearSelected));
            let month = parseInt(this.fromMonthName(monthSelected));
            let date = parseInt(this.toEnglishNumber(dateSelected));
            let hour =
                offsetSelected === "بعد از ظهر"
                    ? parseInt(this.toEnglishNumber(hourSelected)) + 12
                    : parseInt(this.toEnglishNumber(hourSelected));
            let minute = parseInt(this.toEnglishNumber(minuteSelected));
            let georgiantime = this.jalaliToMiladiDate(
                year + "/" + this.updateNumber(month) + "/" + this.updateNumber(date)
            );
            let dateObject = new Date(
                georgiantime._d.getFullYear(),
                georgiantime._d.getMonth(),
                georgiantime._d.getDate(),
                hour,
                minute
            );
            return dateObject.getTime();
        },
        validation(
            yearSelected,
            monthSelected,
            dateSelected,
            hourSelected,
            offsetSelected,
            minuteSelected,
        ) {
            minuteSelected = parseInt(this.toEnglishNumber(minuteSelected)) + 1;
            minuteSelected = this.toFarsiNumber(minuteSelected);
            return (
                this.createTimeStampWithDateInformation(
                    yearSelected,
                    monthSelected,
                    dateSelected,
                    hourSelected,
                    offsetSelected,
                    minuteSelected
                ) > this.currentTimeStampDate()
            );
        },
        validationBirth(
            yearSelected,
            monthSelected,
            dateSelected,
            hourSelected,
            offsetSelected,
            minuteSelected
        ) {
            minuteSelected = parseInt(this.toEnglishNumber(minuteSelected)) + 1;
            minuteSelected = this.toFarsiNumber(minuteSelected);
            return (
                this.createTimeStampWithDateInformation(
                    yearSelected,
                    monthSelected,
                    dateSelected,
                    hourSelected,
                    offsetSelected,
                    minuteSelected
                ) <= this.currentTimeStampDate()
            );
        },
        getValidYearListBirth() {
            let yearList = [];
            for (let i = 0; i <= 100; i++) {
                yearList.push(this.toFarsiNumber(this.currentYear() - i));
            }
            return yearList;
        },
        isStartDateLessThanEndDate(startDate, endDate) {
            return startDate <= endDate;
        },
        currentTimeStampDate() {
            return new Date().getTime();
        },
        getCurrentDate() {
            return this.miladiToJalaliDate(this.getNowMiladiDate());
        },
        currentYear() {
            return this.getCurrentDate().jYear();
        },
        currentMonth() {
            return this.getCurrentDate().jMonth() + 1;
        },
        currentDate() {
            return this.getCurrentDate().jDate();
        },
        currentHour() {
            let hour = new Date().getHours();
            if (hour < 12) {
                return {hour: hour, offset: 0};
            } else {
                return {hour: hour - 12, offset: 1};
            }
        },
        currentMinute() {
            return new Date().getMinutes();
        },
    },
};