<template>
  <div class="tw-flex tw-flex-col tw-w-full tw-p-6 tw-bg-white tw-h-screen  ">
    <div class="tw-items-start tw-flex tw-mb-10 ">
      <v-icon size="24px" color="#0A040D" @click="goBack">mdi-chevron-left</v-icon>
    </div>
    <div class="tw-flex tw-relative tw-flex-col tw-justify-center tw-items-center tw-w-full">
      <div v-if="getUserProfile?.avatar === null" class="tw-w-20 tw-h-20 tw-bg-[#E8E8E8] tw-flex tw-justify-center tw-rounded-full">
        <v-icon  size="80px" v-if="uploaded_picture === null">mdi-account</v-icon>
        <img v-else :src="uploaded_picture"  alt="" class="tw-rounded-full">
        <input type="file" @change="onFileChange" ref="userPicture" class="tw-hidden" />
        <div class="tw-absolute tw-flex tw-justify-center tw-items-center tw-w-6 tw-h-6 tw-bg-[#662483] tw-border tw-border-white tw-rounded-full" style="  top: auto; bottom: -10%;  " @click="changeUserImage">
          <v-icon color="white" size="16px">mdi-pencil-outline</v-icon>
        </div>
      </div>
      <div v-else class="tw-relative tw-w-20 tw-h-20 tw-bg-[#E8E8E8] tw-flex tw-justify-center tw-rounded-full">
        <img :src="getUserProfile?.avatar" alt="" class="tw-w-20 tw-h-20">
        <input type="file" @change="onFileChange" ref="userPicture" class="tw-hidden" />
        <div class="tw-absolute tw-flex tw-justify-center tw-items-center tw-w-6 tw-h-6 tw-bg-[#662483] tw-border tw-border-white tw-rounded-full" style="  top: auto; bottom: -10%;  " @click="changeUserImage">
          <v-icon color="white" size="16px">mdi-pencil-outline</v-icon>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-mt-12 tw-w-full">
      <v-form lazy-validation v-model="formValidation" ref="userprofileForm">
        <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-y-2 ">
          <span class="tw-text-[#888888] tw-text-sm tw-font-medium">Username</span>
          <v-text-field height="48px" :readonly="!isEditUsername" outlined color="#222222" style="width: 100%;border-radius: 8px"  dense full-width v-model="username" >
            <template #append>
              <v-icon color="#662483" @click="editUsername">{{ isEditUsername ?  'mdi-check' : 'mdi-pencil-outline' }}</v-icon>
            </template>
          </v-text-field>
        </div>
        <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-y-2">
          <span class="tw-text-[#888888] tw-text-sm tw-font-medium">Email</span>
          <v-text-field height="48px" readonly outlined color="#222222" style="width: 100%;border-radius: 8px" dense full-width v-model="email"/>
        </div>
        <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-y-2">
          <span class="tw-text-[#888888] tw-text-sm tw-font-medium">Password</span>
          <v-text-field value="........" readonly height="48px" outlined color="#222222" style="width: 100%;border-radius: 8px" type="password" hide-details  dense full-width>
            <template #append >
              <v-icon color="#662483" @click="changePasswordPage">mdi-pencil-outline</v-icon>
            </template>
          </v-text-field>
        </div>

        <v-btn color="#662483" height="48" width="100%" style="border-radius: 8px" elevation="0" class="tw-mt-10">
          <span class="tw-normal-case tw-text-white">Save changes</span>
        </v-btn>
      </v-form>

    </div>

  </div>

</template>

<script>
import Bg from "@/assets/svg/Bg.svg"
import {mapActions, mapGetters} from "vuex";

export default {
  name : "Profile",
  data:()=>({
    Bg,
    isEditUsername : false,
    isEditPassword : false,
    username : '',
    email : '',
    formValidation : false,
    uploaded_picture : null

  }),
  computed:{
    ...mapGetters({
      getUserProfile : "userprofile/getUserProfile"
    })
  },
  methods:{
    ...mapActions({
      fetchUserProfile : "userprofile/fetchUserProfile"
    }),
    goBack(){
      this.$router.push("/")
    },
    onFileChange(e){
      console.log(e)
      this.uploaded_picture = URL.createObjectURL(e.target.files[0])
      console.log(this.uploaded_picture);
    },
    editUsername(){
      if(this.isEditUsername){
        //Todo set username
      }
      else{
        //Todo change username
      }
      this.isEditUsername = !this.isEditUsername
    },
    changePasswordPage(){
      this.$router.push("/password")
    },
    changeUserImage(){
      this.$refs.userPicture.click()
    }
  },
  async mounted() {
    await this.fetchUserProfile()
    console.log(this.getUserProfile);
    this.username = this.getUserProfile.username
    this.email = this.getUserProfile.email
  }
}
</script>

<style scoped>

</style>