<template>
  <div class="tw-bg-[#F5F7F6] tw-p-4 tw-flex tw-flex-col" style="border-radius: 12px">
    <div class="tw-flex tw-justify-between tw-items-center">
      <span class="tw-text-sm tw-font-medium tw-text-[#222222]">{{comment.author}}</span>
      <span class="tw-text-xs tw-text-[#C4C4C4]">{{convertTimeToSuitableTime(comment.created_at)}}</span>
    </div>
    <div class="commentText tw-text-sm tw-text-[#444444]">
      {{comment.text}}
    </div>
    <div class="tw-flex tw-gap-x-2 tw-items-center tw-mt-4">
      <img :src="comment.is_liked ? this.redFillLike : this.grayLike" alt="" width="20px" >
      <img :src="comment.is_disliked ? this.redFillLike : this.grayLike" alt="" style="transform: rotate(180deg)" width="20px">
    </div>
  </div>
</template>

<script>
import grayLike from "@/assets/svg/grayLike.svg"
import redFillLike from "@/assets/svg/redFillLike.svg"
import {dateHelper} from "@/mixins/dateHelpers";
export default {
  name : "allCommentOneComment",
  mixins:[dateHelper],
  data:()=>({
    grayLike,
    redFillLike

  }),
  methods:{

  },
  props:{
    comment : {}
  }
}
</script>



<style scoped>
.commentText{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>