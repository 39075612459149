const similarVideosAPI = async function (params){
    return similarVideosFN(params).then((value)=>{
        return value
    })
}


const similarVideosFN=(params)=>new Promise(function (resolve){
    console.log(params);
    if(params.level === 1)
         resolve({
        data : {
            count : 4,
            results : [
                {level : 1 , title : "Test Film" , duration : 64 , year : 1995 },
                {level : 1 , title : "Test Film" , duration : 64 , year : 1995 },
                {level : 1 , title : "Test Film" , duration : 64 , year : 1995 },
                {level : 1 , title : "Test Film" , duration : 64 , year : 1995 },
            ]
        }
    })
    else if(params.level === 2)
        resolve({
            data : {
                count : 4,
                results : [
                    {level : 2 , title : "Test Film" , duration : 64 , year : 1995 },
                    {level : 2 , title : "Test Film" , duration : 64 , year : 1995 },
                    {level : 2 , title : "Test Film" , duration : 64 , year : 1995 },
                    {level : 2 , title : "Test Film" , duration : 64 , year : 1995 },
                ]
            }
        })
    else if(params.level === 3)
        resolve({
            data : {
                count : 4,
                results : [
                    {level : 3 , title : "Test Film" , duration : 64 , year : 1995 },
                    {level : 3 , title : "Test Film" , duration : 64 , year : 1995 },
                    {level : 3 , title : "Test Film" , duration : 64 , year : 1995 },
                    {level : 3 , title : "Test Film" , duration : 64 , year : 1995 },
                ]
            }
        })



})

export {
    similarVideosAPI
}