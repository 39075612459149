<template>
  <div class="tw-flex tw-justify-between tw-w-full tw-items-center ">
    <slot></slot>

    <div class="tw-flex tw-gap-x-2 tw-items-center">
      <v-menu offset-x :close-on-content-click="false" right >
        <template v-slot:activator="{ on, attrs }">

          <img :src="menu" alt="" v-bind="attrs"
               v-on="on" />
        </template>
        <div class="tw-bg-white tw-flex tw-flex-col tw-gap-y-3" style="padding: 16px 48px 16px 16px !important;border-radius: 12px !important;border: 1px solid #F2F2F2" >
          <img :src="userAvatar" alt="" class="tw-w-10 tw-h-10">
          <div class="tw-flex tw-flex-col tw-pb-3" style="border-bottom: 1px solid #F6F6F6">
            <span class="tw-text-[#222222] tw-text-sm tw-font-normal">{{getUserProfile?.username}}</span>
            <span class="tw-text-fs11 tw-text-[#888888]">Matt2256.@gmail.com</span>
          </div>

          <div class="tw-flex tw-gap-x-2 tw-items-center" v-for="(item, index) in menuItems" :key="index" @click="clickAction(item)">
            <img :src="item.src" alt="" style="width: 16px; height: 16px">
            <span class="tw-text-sm" :style="`color:${item.color}`">{{ item.title }}</span>
          </div>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import menu from "@/assets/menu.svg";
import userAvatar from "../../assets/Profile.svg"
import setting from "@/assets/setting.svg";
import pricing from "@/assets/pricing.svg";
import logout from "@/assets/svg/red_logout.svg";
import profile from "@/assets/svg/userprofile.svg"



export default {
  name  : 'userTopBar',
  data:()=>({
    menu,
    userAvatar,
    setting,
    pricing,
    logout,
    profile

  }),
  computed:{
    ...mapGetters({
      getUserProfile : "userprofile/getUserProfile"
    }),
    menuItems() {
      return [{title : 'Profile' , src : this.profile,color : "#222222",link : '/profile'},{title : 'Setting' , src : this.setting,color : "#222222",link : '/setting'},{title : 'Pricing' , src : this.pricing,color : "#222222",link:'/pricing'},{title : 'Logout' , src : this.logout,color : '#E95D5D'}]

    },
  },
  methods:{
    clickAction(item){
      if(item.link){
        this.$router.push(item.link)
      }
    }
  }
}

</script>
<style scoped>

</style>