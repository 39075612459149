<template>
  <div >
    <div v-if="!loading">
      <div  class="tw-flex tw-flex-col tw-p-6 tw-gap-y-6">
        <UserTopBar>
          <template>
            <div class="tw-flex tw-gap-x-4">
              <v-icon color="#0B131A" class="tw-w-6 tw-h-6 " @click="backToHome">mdi-chevron-left</v-icon>
  <!--            <v-icon color="#0B131A" class="tw-w-6 tw-h-6">mdi-bookmark-outline</v-icon>-->
            </div>
          </template>
        </UserTopBar>
        <OneGenreFilm :film.sync="currentFilmComputed" :is-one-film-page="true"  />
        <v-btn :color="currentFilmComputed?.level === 1 ? '#662483' : currentFilmComputed?.level === 2 ? '#70BAEF' : '#FEB549'" elevation="0" height="48" style="border-radius: 8px" :ripple="false" @click="changePage">
          <span class="tw-text-white tw-normal-case" >Watch</span>
        </v-btn>
        <div class="tw-bg-[#F5F7F6] tw-p-4 tw-rounded-[16px]">
          <span class="tw-text-sm">{{calculateText(currentFilmComputed?.summary)}}</span>
          <span v-if="currentFilmComputed?.summary !== ''" @click="showMore = !showMore" class="tw-pl-2 tw-text-[#6196CF] tw-underline">{{showMore ? 'less' : 'more'}}</span>
        </div>
        <div class="tw-flex tw-flex-col tw-w-max tw-max-w-full tw-gap-y-3 tw-overflow-x-hidden" v-if="currentFilmComputed?.actors.length !==0">
          <span>Actors</span>
          <div class="tw-flex tw-gap-x-3 tw-overflow-x-scroll tw-w-max scrollbar-hide">
            <div v-for="(actor,index) in currentFilmComputed?.actors" :key="index" class="tw-px-3 tw-py-1  tw-bg-[#F5F7F6] tw-rounded-[33px] ">
              <span class="tw-text-sm tw-text-[#222222]">{{actor.name}}</span>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-w-max tw-max-w-full tw-gap-y-3 tw-overflow-x-hidden" v-if="currentFilmComputed?.directors.length !==0">
          <span>{{currentFilmComputed?.directors.length > 1 ?  'Directors' : 'Director' }}</span>
          <div class="tw-flex tw-gap-x-3 tw-overflow-x-scroll tw-w-max scrollbar-hide">
            <div v-for="(director,index) in currentFilmComputed?.directors" :key="index" class="tw-px-3 tw-py-1  tw-bg-[#F5F7F6] tw-rounded-[33px] ">
              <span class="tw-text-sm tw-text-[#222222]">{{director.name}}</span>
            </div>
          </div>
        </div>

      </div>
      <div class="tw-flex tw-bg-[#F5F7F6] tw-flex-col tw-p-6 tw-gap-y-4" style="aspect-ratio: 4/3" >
        <div class="tw-flex tw-justify-between tw-items-center tw-w-full ">
          <span class="tw-font-normal ">Comments</span>
          <span class="tw-text-xs tw-normal-light" :class="currentFilmComputed?.level === 1 ? 'tw-text-[#662483]' : currentFilmComputed?.level === 2 ? 'tw-text-[#70BAEF]' : 'tw-text-[#FEB549]'" @click="seeAllComments">See all</span>
        </div>
        <div class="tw-flex tw-flex-row tw-gap-x-6 tw-overflow-x-auto tw-h-3/5 " >
          <one-film-comments :comment="comment" v-for="(comment,index) in this.sample_comments" :key="index"/>
        </div>
        <div class="tw-w-full tw-h-12 ">
          <v-text-field  hide-details  @keyup.enter="addComment" placeholder="Write your comment..." outlined dense class="tw-w-full tw-text-xs" v-model="comment_text" background-color="white" style="border-radius: 41px;direction: ltr" color="#8E8E8E">
            <template #append>
              <v-icon :disabled="comment_text === ''" :color="comment_text === '' ? '#C4C4C4' : '#6196CF'" class="tw-cursor-pointer" @click="addComment">mdi-arrow-up-circle</v-icon>
            </template>
          </v-text-field>
        </div>
      </div>
      <Similar_videos class="tw-p-6" :level="currentFilmComputed?.level"/>

    </div>
    <div v-else class="tw-w-full tw-flex tw-items-center tw-justify-center tw-h-screen">
      <v-progress-circular color="#1c78f2" indeterminate size="24" class="" style=""/>
    </div>
  </div>
</template>

<script>
import UserTopBar from "@/components/common/UserTopBar.vue";
import OneGenreFilm from "@/components/genre/oneGenreFIlm.vue";
import {mapActions, mapGetters} from "vuex";
import OneFilmComments from "@/components/comments/oneFilmComments.vue";
import Similar_videos from "@/components/FIlm/similar_videos.vue";

export default {
  name : "oneFilm",
  components:{Similar_videos, OneFilmComments, OneGenreFilm, UserTopBar},
  async mounted() {
    this.loading = true
    await this.fetchCurrentFilm(this.$route.params.id)
    this.loading = false
  },
  computed:{
    ...mapGetters({
      getCurrentFilm :  'movies/getCurrentFilm'
    }),

    currentFilmComputed(){
      if(this.getCurrentFilm?.results !== undefined)
        return this.getCurrentFilm?.results
      return {}
    }
  },
  data:()=>({
    showMore: false,
    loading : false,
    comment_text : '',
    sample_comments : [
        {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfg"},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfg"},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfg"},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfg"}]
    // currentFilm  : {id : 80,hardness : 2,videoSrc : '',thumbnail : '',title : 'The Bucket List',created_year : '2020' , directors : ['Rob Reiner'],total_time : 97 ,country : ['United States'],summary : "\"The Bucket List\" is a heartwarming tale of friendship and self-discovery that follows two terminally ill men, corporate billionaire Edward Cole and working-class mechanic Carter Chambers. After finding themselves sharing a hospital room, the unlikely duo decides to break free and embark on an epic adventure. Armed with a wish list of activities to accomplish before they \"kick the bucket,\" Edward and Carter travel the world together, experiencing the joys of life like never before. As their journey progresses, they not only fulfill their dreams but also find the joy in new friendships and the beauty of the world around them, proving that it's never too late to live your life to the fullest.",actors : ["Jack Nicholson", "Morgan Freeman" ,"Sean Hayes"],genre : ["Adventure","Comedy","Drama"]}
  }),
  methods:{
    ...mapActions({
      fetchCurrentFilm  : "movies/fetchCurrentFilm",
      changeCurrentFilm : "movies/changeCurrentFilmProperty"
    }),
    seeAllComments(){
      this.$router.push(`/film/${this.currentFilmComputed.id}/comments`)
    },
    addComment(){

    },
    changePage(){
      this.$router.push(`/translate/${this.currentFilmComputed.id}`)
    },
    backToHome(){
      this.$router.push('/')
    },
    calculateText(input){
      if (input !== null && input !== ''){
        if (this.showMore) {
          return  input.replaceAll(/<\/?[^>]+(>|$)/gi, "");
        }
        return  input?.substr(0, 150).replaceAll(/<\/?[^>]+(>|$)/gi, "") + ' ...';

      }
      return ''
    }
  }
}
</script>

<style scoped>

</style>