<template>
  <div class="tw-w-full tw-flex tw-items-center tw-relative tw-overflow-hidden tw-h-full" style="height: 20px" @mousemove="move" @mouseup="end" @touchend="end" >
    <!-- Fixed Pointer Div -->

    <div id="pointer" class="tw-bg-[#662483] tw-absolute tw-left-1/2 tw-rounded-12" style="width: 5px;height: 20px;z-index: 5"></div>

    <!-- Draggable Content Container -->

    <div ref="draggableContainer" id="draggableContainer"  :style="`width:${getMovieWaveData.total_time * 10}px ; height : 20px; left:calc(50% + ${getMarginLeftParameter}px)`" class="tw-flex tw-bg-white  tw-absolute tw-items-center tw-gap-x-2 tw-py-1 tw-cursor-grab" @mouseleave="end"  @touchmove="move" @touchstart="start" @touchend="end" @mousedown="start" @mouseup="end" @mousemove="move">
      <div v-for="(part, index) in getMovieWaveData?.contents" :key="index"  :style="`height : ${part.sentence_length/getMovieWaveData.max_sentence_length * 12}px;` " style="width: 10px" class="tw-bg-[#dbdbdb] tw-rounded-[2px]"  >
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "TimeSeriesPlayer",
  data: () => ({
    currentText: '',
    isDragging: false,
    initialClickPosition: 0,
    initialScrollPosition: 0,
    diff : 0,
    pointerX : null,
    pointerY : null,
    currentPartTime: 0,
    playInterval : null,
    timeChange : 0,
    directionChange : '',
    marginLeftParameter : 0,
    lastDiff : 0
  }),
  mounted() {
    const element = document.getElementById('pointer');
    const rect = element.getBoundingClientRect();
    this.pointerX = rect.left + window.scrollX;
    this.pointerY= rect.top + window.scrollY;
    this.$on("pause",()=>{
      clearInterval(this.getPlayInterval)
      this.setPlayInterval(null)
      if(this.getIsPlaying){
        this.$emit("changeIsPlaying",false)
      }
    })


      document.getElementById("draggableContainer").style.left = `calc(50% + ${this.getMarginLeftParameter}px )`
      this.findCurrentPartUnderPointer()

  },
  methods: {
    ...mapMutations({
      setIsPlaying : "setting/SET_IS_PLAYING",
      setMarginLeftParameter : "setting/SET_MARGIN_LEFT_PARAMETER",
      setPlayInterval : "setting/SET_PLAY_INTERVAL",
      setCurrentTime : "setting/SET_CURRENT_TIME",
      setChangeTimeFlag : "setting/SET_CHANGE_TIME_FLAG"
    }),
    start(event) {
      this.isDragging = true;
      this.initialClickPosition = event.clientX || event.changedTouches[0].clientX;

    },

    end(){
      this.isDragging = false;
    },


    move(event){
      if (!this.isDragging) return;
      const currentPosition = event.clientX || event.changedTouches[0].clientX;
      this.lastDiff = this.diff
      this.diff = currentPosition - this.initialClickPosition;


      if(this.lastDiff - this.diff > 0 && this.diff <= 0)  {
        if (Math.abs(this.getMarginLeftParameter + this.diff) <= this.getMovieWaveData.total_time * 10) {
          this.setMarginLeftParameter(this.getMarginLeftParameter + this.diff)
          // document.getElementById("draggableContainer").style.left = `calc(50% + ${this.marginLeftParameter}px )`
          document.getElementById("draggableContainer").style.left = `calc(50% + ${this.getMarginLeftParameter}px )`

        } else {
          this.setMarginLeftParameter((this.getMovieWaveData.total_time -1) * -10)
          document.getElementById("draggableContainer").style.left = `calc(50% + ${this.getMarginLeftParameter}px )`

        }
      }
      else{
        if(this.getMarginLeftParameter+Math.abs(this.lastDiff - this.diff) < 0){
          document.getElementById("draggableContainer").style.left = `calc(50% + ${this.getMarginLeftParameter+Math.abs(this.lastDiff - this.diff)}px )`
          this.setMarginLeftParameter( this.getMarginLeftParameter+Math.abs(this.lastDiff - this.diff))
        }
        else{
          document.getElementById("draggableContainer").style.left = `calc(50% + 0px )`
          this.setMarginLeftParameter(0)
        }
      }
      this.findCurrentPartUnderPointer();
    },
    findCurrentPartUnderPointer() {
      const containerRect = document.getElementById("draggableContainer")?.getBoundingClientRect();
      if(containerRect !== undefined){
        const relativePointerX = this.pointerX - containerRect.left;
        const partWidth = 10; // Assuming each part has a fixed width of 10px
        const partIndexUnderPointer = Math.floor(relativePointerX / partWidth);
        if(partIndexUnderPointer >= 0 && partIndexUnderPointer < this.getMovieWaveData.total_time) {
          const currentPart = this.getMovieWaveData.contents[partIndexUnderPointer];
          this.currentPartTime = currentPart.time;
          this.setCurrentTime(currentPart)
        }
      }
    },
    play(){
      this.$emit("play")
    },
    changePosition(){


      if(this.directionChange === 'forward'){
        if(this.getCurrentTime.time + this.timeChange <= this.getMovieWaveData.total_time - 1  ){
          document.getElementById("draggableContainer").style.left = `calc(50% + ${this.getMarginLeftParameter - (this.timeChange * 10)}px )`
          this.setMarginLeftParameter(this.getMarginLeftParameter - (this.timeChange * 10))
        }
        else{
          document.getElementById("draggableContainer").style.left = `calc(50% + ${-(this.getMovieWaveData.total_time) * 10}px )`
          this.setMarginLeftParameter((this.getMovieWaveData.total_time) * -10)
        }
      }
      else{
        if(Math.abs(this.getMarginLeftParameter + (this.timeChange * 10)) >= 0){
          if(this.getCurrentTime.time - this.timeChange >=0){
            document.getElementById("draggableContainer").style.left = `calc(50% + ${this.getMarginLeftParameter + (this.timeChange * 10)}px )`
            this.setMarginLeftParameter(this.getMarginLeftParameter + (this.timeChange * 10))
          }
          else{
            document.getElementById("draggableContainer").style.left = `calc(50% + 0px )`
            this.setMarginLeftParameter(0)
          }
        }

      }
      if(!this.getIsPlaying){
        this.findCurrentPartUnderPointer()
      }
      this.directionChange = ''
      this.timeChange = 0
      // this.setChangeTimeFlag(true)
    },


  },
  watch:{
    getIsPlaying(newValue){
      if(newValue){
        this.play()
      }
      else{
          this.$emit("pause")
      }
    },
    timeChange(newValue){
      console.log("new value",newValue);
      if(newValue !==0){
        this.changePosition()
      }

    }


  },
  props:{
    marginLeftProps:{

    }
  },
  computed:{
    ...mapGetters({
      getMovieWaveData : "movieWave/getMovieWaveData",
      getIsPlaying : "setting/getIsPlaying",
      getMarginLeftParameter : "setting/getMarginLeftParameter",
      getPlayInterval : "setting/getPlayInterval",
      getChangeTimeFlag : "setting/getChangeTimeFlag",
      getCurrentTime : "setting/getCurrentTime"
    })
  }
}
</script>

<style>
</style>