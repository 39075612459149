<template>
  <div class="tw-grid tw-gap-x-3 tw-grid-cols-[33%,67%]" @click="goToOneFilm">
    <div class="tw-flex tw-flex-col tw-rounded-[12px] " :style="`backgroundImage : ${film?.poster ? `url(${film?.poster})` : null}`" :class="film?.poster === null ? film.level === 1 ? 'tw-bg-[#D1BDDA]' : film.level ===2 ? 'tw-bg-[#A9D6F5]' : 'tw-bg-[#FEC46D]' :''">
      <div class="tw-flex tw-flex-col tw-p-4 ">
        <span class="tw-text-xs tw-text-[#0B131A] tw-font-medium">{{film?.title}}</span>
        <span class="tw-text-fs8 tw-mb-5 tw-text-[#0B131A]">{{film?.director}}</span>
      </div>
      <img :src="moviePic"  alt="">
      <div class="tw-h-10 tw-mt-auto tw-mb-0 tw-w-full tw-rounded-b-[12px]" :class="film.level === 1 ? 'tw-bg-[#EFE9F2]' : film.level ===2 ? 'tw-bg-[#E0F4FF]' : 'tw-bg-[#FFE1B6]'"></div>
    </div>
    <div class="tw-flex tw-flex-col tw-w-full">
      <span class="tw-text-[#222222] tw-text-base tw-font-medium ">{{film?.title}}</span>
      <div class="tw-flex tw-gap-x-4 tw-text-[#888888] tw-text-sm tw-mt-3" v-if="isOneFilmPage">
        <span>{{calculateLevel(film?.level)}}</span>
        <span>{{(film?.duration)}}min</span>
      </div>
      <div class="tw-flex tw-gap-x-2 tw-text-[#888888] tw-text-sm tw-items-center tw-mt-4 tw-mb-5">
        <img class="tw-w-[18px]" :src="mapFlag(film?.countries[0]?.name)" alt="">
        <span>{{film?.countries[0]?.name}}</span>
        <span>|</span>
        <span>{{film?.year}}</span>
      </div>
      <div class="tw-flex tw-gap-x-3 tw-overflow-x-scroll tw-w-[4/10] scrollbar-hide" v-if="isOneFilmPage">
        <div class="tw-px-3 tw-py-1 tw-bg-[#F5F7F6] tw-rounded-[33px]" v-for="(genre,index) in film?.genres" :key="index">
          <span class="tw-text-fs11 tw-text-[#222222]">{{genre.name}}</span>
        </div>
      </div>
      <div class="tw-flex tw-gap-x-2 tw-items-center" v-if="!isOneFilmPage">
        <div class="tw-bg-[#F2F2F2] tw-rounded-full tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center">
          <v-icon color="#222222">mdi-play</v-icon>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-x-2">
          <span class="tw-text-sm tw-text-[#222222]">{{secondToMinute(film?.duration)}} min</span>
<!--          <span class="tw-text-xs tw-text-[#888888]">{{secondToMinute(film?.watched_time)}} min Watched</span>-->
        </div>
      </div>

    </div>

  </div>
</template>


<script>
import moviePic from "../../assets/svg/Vector.svg"
import Germany from "../../assets/Germany.svg";
import American from "../../assets/svg/American.svg";
import {mapActions} from "vuex";
export default {
  name : "oneGenreFilm",
  computed: {
  },
  props:{
    film : {},
    isOneFilmPage : {
      default : false,
      type : Boolean
    },

  },
  data:()=>({
    moviePic,
  }),
  methods:{
    ...mapActions({
      changeCurrentFilmProperty : "movies/changeCurrentFilmProperty"
    }),
    secondToMinute(time){
      return Math.ceil(time/60)
    },
    convertSeconds(totalSeconds) {
      let hours = Math.floor(totalSeconds / 3600);
      let remainingSeconds = totalSeconds % 3600;
      let minutes = Math.floor(remainingSeconds / 60);
      let seconds = remainingSeconds % 60;
      return `${hours !==0 ? hours + 'h' + minutes +'m' : minutes !== 0 ? minutes + 'm' : seconds + 's'   } `
    },
    goToOneFilm(){
      this.changeCurrentFilmProperty(this.film)
      this.$router.push(`/film/${this.film.id}`)
    },
    calculateLevel(input){
      if(input ===1){
        return "Elementary"
      }
      else if (input ===2){
        return "Intermediate"
      }
      else {
        return  "Advanced"
      }
    },
    mapFlag(input){
      if (input === "United States")
        return American
      return  Germany
    }
  },
}

</script>



<style scoped>
.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid red;
  position: relative;
  overflow: hidden; /* Ensures the overlay does not extend beyond the circle */
}

.circle::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* Match this with your page/container background */
  transform-origin: center;
  transform: rotate(45deg); /* Adjust rotation based on which quarter you want to show */
}
</style>