<template>
  <div class="tw-flex tw-flex-col tw-h-screen ">
    <div class="tw-bg-[#F5F7F6] tw-flex tw-flex-col">
      <div class="tw-flex tw-gap-x-4 tw-py-6 tw-px-6  tw-justify-center tw-relative tw-w-full tw-items-center">
        <v-icon color="black" size="24" @click="back" style="position:absolute; left: 24px" >mdi-chevron-left</v-icon>
        <span class="tw-text-2xl tw-text-black tw-text-[#F5F7F6]">Real</span>
      </div>
    </div>
    <div class="tw-flex tw-flex-1 tw-pb-6">
      <chat-box  />
    </div>

  </div>
</template>

<script>
import ChatBox from "@/components/Chat/ChatBox.vue";

export default {
  name : "userWordsChat",
  components: {ChatBox},
  data:()=>({

  }),
  methods:{
    back(){
      this.$router.push("/words")
    },
  }
}

</script>

<style scoped>

</style>