import {similarVideosAPI } from "@/api/similarVideos";
const state=({
    similarVideos : []
})

const actions={

    async fetchSimilarVideos({commit},payload){
        try {
            let response = await similarVideosAPI(payload)
            commit("SET_SIMILAR_VIDEOS",response.data)
        }
        catch {
            //
        }
    },

}

const mutations ={
    SET_SIMILAR_VIDEOS(state, data){
        state.similarVideos = data;
    },

}
const getters ={
    getSimilarVideos(state){
        return state.similarVideos
    },
}
export  default {
    namespaced : true,
    state,
    actions,
    getters,
    mutations
}
