<template>
  <div
      class="tw-mb-2 tw-flex tw-flex-col tw-border tw-border-[#DBDBDB]"
      v-click-outside="closeCall">
    <div
        id="activeTagMenu"
        @click.self="menu =true"
        class="tw-flex tw-rounded-[4px] tw-p-2 "
        style="border: 1px solid #4E4E4E ;height: 40px !important; width: 200px"
    >
      <span class="tw-text-sm">{{selectedChat}}</span>
    </div>
    <div class="tw-flex tw-flex-col" style="width: 200px" v-if="menu">
      <v-expand-transition  >
      <div class="tw-px-4 tw-pt-4 tw-bg-white">
        <div
            class="  tw-border-t-0   tw-flex tw-flex-col tw-justify-center tw-items-center  ">
          <v-text-field
              ref="searchBar"
              v-model="search"
              outlined
              color="black"
              light
              height="32px"
              background-color="white"
              required
              hide-details
              maxlength="20"
              @keyup.enter="addTagToList(search,true)"
              append-icon="mdi-magnify"
              class=" tw-rounded-t-md " style="border: 1px solid #DBDBDB">
          </v-text-field>
          <div  class="tw-h-[50px] tw-overflow-auto tw-bg-[white] tw-gap-y-4 tw-w-full tw-mt-4 tw-flex tw-flex-col">
            <div class="tw-flex tw-gap-x-2 tw-items-center" v-for="(item , index) in items" :key="index">
              <span  @click="updateSelector(item)" class="tw-text-sm" :class="selected_item === item.id ? 'tw-text-[#662483]' : 'tw-text-[#888888]'"  >{{item.content}}</span>
            </div>
          </div>
        </div>
      </div>
    </v-expand-transition>
    </div>
  </div>
</template>

<script>

export default {
  name: "zhSelector",
  props: {
    hideNoData: {
      default: false
    },
    required: {
      default: false,
    },
    maximumNumber: {
      default:5,
      type: Number
    },
    error: {
      default: false,
      type: Boolean,
    },
    hasMaximumNumber:{
      default:true,
      type:Boolean,
    },
    items : {

    },
    selected_item:{

    },
    selectedChat:{

    }
  },
  data: () => ({
    menu: false,
    search: '',
    show: true,
    activator: null,
    attach: null,
    nonce: 1,
    setTimeOut:null,

  }),
  methods: {
    updateSelector(item){

      this.$emit("chanegSelector",item)
    },
    closeCall() {
      this.menu = false
    },
    addTagToList(tag, enter = false) {
      console.log(enter);
      console.log(tag);

    },
  },
  computed: {
    tagsPayload() {
      return {
        page: 1,
        page_size: 10,
      }
    },
  },
  watch: {
    async search(val) {
      if(this.setTimeOut){
        clearTimeout(this.setTimeOut)
      }
      this.setTimeOut = setTimeout(async ()=>{
          this.tagsPayload['search'] = val
          this.$emit("search",val)

      },500)

    },
  },
}
</script>
<style>
.tags-text-field .v-text-field .v-input__prepend-inner, .v-text-field .v-input__append-inner {
  margin-top: 0 !important;
  align-self: center !important;
}

.tags-text-field .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 32px !important;
}
</style>
