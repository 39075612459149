<template>
  <div class="tw-flex tw-w-full tw-px-8 tw-relative tw-bg-[#F5F7F6] tw-h-screen tw-justify-center tw-overflow-hidden tw-items-center">
    <img :src="Bg" alt="" class="tw-absolute tw-h-screen tw-w-full" style="object-fit: cover">
    <div class="tw-w-full tw-p-6 tw-bg-white tw-flex tw-flex-col tw-h-max tw-z-10" style="border-radius: 16px;border: 1px solid #F2F2F2" >
      <span class="tw-text-[#222222] tw-text-xl tw-font-medium" >{{ state === 0 ? 'Login' : state === 1 ? 'Forget Password?' : state === 2 ? 'Check Your Email' : 'Change Password'   }}</span>
      <span class="tw-text-xs tw-mt-2 tw-text-[#888888]">{{ state === 1 ? ' Enter Your Email' : state === 2 ? `We sent a code to ` +  forgetPassEmail : state === 3 ? 'Your password must be at least 8 characters long.' : '' }}</span>
      <v-form class="tw-flex tw-flex-col  tw-mt-8" lazy-validation v-model="validation" ref="loginForm" @submit="(e)=>e.preventDefault()">
        <div class="tw-flex tw-flex-col" v-if="state === 0">
          <div class="tw-flex tw-flex-col tw-gap-y-2">
            <span class="tw-text-[#888888] tw-text-sm">Email</span>
            <v-text-field dense outlined background-color="white" height="48" color="#222222" v-model="email" :rules="[emailRules(email,false)]" />
          </div>
          <div class="tw-flex tw-flex-col tw-gap-y-2">
            <span class="tw-text-[#888888] tw-text-sm">Password</span>
            <v-text-field dense outlined background-color="white" type="password" v-model="password" height="48" color="#222222"  />
          </div>
          <div class="tw-flex tw-justify-between tw-items-center">
            <div class="tw-flex tw-items-center tw-gap-x-1">
  <!--            <v-checkbox dense hide-details/>-->
              <input type="checkbox" v-model="rememberMe" >
              <span class="tw-text-fs11 tw-text-[#222222]">Remember me</span>
            </div>
            <span class="tw-text-xs tw-font-medium tw-text-[#662483]" @click="forgetPass">Forget Password?</span>
          </div>
          <v-btn color="#662483" class="tw-mt-10" height="48px" style="border-radius: 8px" light :disabled="!validation" @click="login">
            <span class="tw-normal-case tw-text-white">Login</span>
          </v-btn>
          <div class="tw-flex tw-items-center tw-my-4">
            <v-divider />
            or
            <v-divider />
          </div>
          <v-btn color="#F5F7F6" style="border: 1px solid #DBDBDB;border-radius: 8px" height="48px" elevation="0">
            <div class="tw-items-center tw-flex tw-justify-center">
              <img :src="google" alt="">
              <span class="tw-normal-case tw-text-sm">Login with Google</span>
            </div>
          </v-btn>
        </div>

        <div class="tw-flex tw-flex-col" v-if="state === 1">
          <div class="tw-flex tw-flex-col tw-gap-y-2">
            <span class="tw-text-[#888888] tw-text-sm">Email</span>
            <v-text-field dense outlined background-color="white" type="text" :rules="[emailRules(forgetPassEmail)]" v-model="forgetPassEmail" height="48" color="#222222"  />
          </div>
          <v-btn color="#662483" class="tw-mt-4" height="48px" style="border-radius: 8px" light :disabled="!validation" @click="continueFn">
            <span class="tw-normal-case tw-text-white">Continue</span>
          </v-btn>
        </div>

        <div v-if="state === 2" class="tw-flex tw-flex-col tw-gap-y-5 ">
          <v-otp-input class="otpInput" ref="otpInput" length="5" type="number"  :value.sync="code" @finish="verify" @input="changeCode"  />
          <span class="tw-text-sm tw-text-[#888888]">Didn’t get a code? <span class="tw-text-[#222222]"  @click="clickToResend">Click to resend</span></span>
          <v-btn color="#662483"  height="48px" style="border-radius: 8px" light :disabled="!validation" @click="verify">
            <span class="tw-normal-case tw-text-white">Verify</span>
          </v-btn>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-y-0" v-if="state === 3">
          <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-y-2 ">
            <span class="tw-text-[#888888] tw-text-sm tw-font-medium">New Password</span>
            <v-text-field height="48px"  outlined color="#222222" style="width: 100%;border-radius: 8px" type="password" dense full-width v-model="forgetPassNewPassword" :rules="[requireRule(forgetPassNewPassword,true),rangeRules(forgetPassNewPassword,8,16,true)]"  />
          </div>
          <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-y-2 ">
            <span class="tw-text-[#888888] tw-text-sm tw-font-medium">Confirm Password</span>
            <v-text-field height="48px"  outlined color="#222222" style="width: 100%;border-radius: 8px" type="password"  dense  full-width v-model="forgetPassRetypePassword" :rules="[requireRule(forgetPassRetypePassword,true), retypePassRule(forgetPassNewPassword,forgetPassRetypePassword,true)]"  />
          </div>
          <v-btn color="#662483" height="48px" elevation="0" style="border-radius: 8px" width="100%" @click="changePassword" :disabled="!validation" class="tw-mt-4">
            <span class="tw-text-white tw-normal-case">Change Password</span>
          </v-btn>
        </div>


      </v-form>
    </div>
  </div>
</template>
<script>
import google from "@/assets/svg/icons8_google 1.svg"
import {rules} from "@/mixins/rules";
import Bg from "@/assets/svg/Bg.svg"

export default {
  name : 'login',
  mixins : [rules],
  data:()=>({
    rememberMe : false,
    google,
    validation : false,
    email : '',
    Bg,
    password :'',
    state : 0,
    forgetPassEmail:'',
    code : '',
    forgetPassNewPassword :'',
    forgetPassRetypePassword :'',
    passwordValidation : ''

  }),
  watch:{
    state(){
        this.$refs.loginForm.resetValidation()
    },
    code(newValue){
      console.log(newValue);
    }
  },
  methods:{
    changePassword(){

    },
    verify(){
      //Todo call api for verify
      this.state = 3
    },
    changeCode(value){
      //logger for tracking otp code changes
      console.log(value)
    },
    continueFn(){
      this.validation = this.$refs.loginForm.validate()
      if(this.validation)
        this.state = 2
    },
    forgetPass(){
      this.state = 1

    },
    clickToResend(){

      this.$refs.otpInput.otp = [null,null,null,null,null] // to reset the otp value, it has reset function but dont work for me
      //Todo call api for resend code
    },
    login(){
      this.validation = this.$refs.loginForm.validate()

      if(this.validation){
        //Todo call api for login
      }
    }
  }
}
</script>
<style>
.otpInput .v-input__slot{
aspect-ratio: 1/1 !important;
  margin-bottom:  0 !important;
}

</style>