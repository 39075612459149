<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
body {
  height: 100vh;
  margin: 0;
  overflow-x: hidden;
  //overflow: hidden;
}

/* For iOS devices */
@media (max-height: 568px) {
  body {
    /*height: -webkit-fill-available;*/
  }
}

</style>
