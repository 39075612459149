<template>
  <div class="tw-flex tw-flex-col tw-justify-between tw-rounded-[12px] tw-min-h-[220px]"  @click="changePage" :class="movie?.level === 1 ? 'tw-bg-[#D1BDDA]' : movie?.level ===2 ? 'tw-bg-[#A9D6F5]' : 'tw-bg-[#FEC46D]'">
    <div class="tw-grid tw-grid-rows-2 tw-p-4 tw-h-full">
      <div class="tw-flex tw-flex-col">
        <span class="tw-text-base tw-text-[#222222]">{{movie.title}}</span>
        <span class="tw-text-fs10">{{movie.year}}</span>

      </div>
      <div class="tw-flex tw-gap-x-2 tw-items-center">
        <div class="tw-w-7 tw-h-7 tw-bg-[#FFE9DD] tw-flex tw-items-center tw-justify-center tw-rounded-full">
          <v-icon size="20px"  color="#222222">mdi-play</v-icon>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-y-1">
          <span class="tw-text-fs10">{{movie.duration}} min</span>
<!--          <span class="tw-text-fs8 tw-text-[#AE7353]">{{secondToMinute(movie.watched_time)}} min watched </span>-->
        </div>
      </div>
    </div>
    <div>
      <img :src="picture" class="tw-w-full" alt="">
      <div class="tw-h-10  tw-w-full tw-rounded-b-[12px]" :class="movie?.level === 1 ? 'tw-bg-[#EFE9F2]' : movie?.level === 2 ? 'tw-bg-[#E0F4FF]' : 'tw-bg-[#FFE1B6]'"></div>
    </div>

  </div>
</template>

<script>
import moviePic from "../../assets/movie.svg"
import picture from "../../assets/svg/Vector.svg"
import {mapActions} from "vuex";
export default {
  name : "oneMovieContainer",
  props:{
    movie:{},
  },
  data:()=>({
    moviePic,
    picture
  }),
  computed:{

  },
  methods:{
    ...mapActions({
      changeCurrentFilmProperty : "movies/changeCurrentFilmProperty"
    }),
    secondToMinute(time){
      return Math.ceil(time/60)
    },
    changePage(){
      this.changeCurrentFilmProperty(this.movie)
      this.$router.push(`/film/${this.movie.id}`)
    }
  },

}
</script>
<style scoped>

</style>