<template>
  <div class="tw-flex tw-flex-col tw-gap-y-4">
    <div class="tw-flex tw-justify-between">
      <span class="tw-text-[#222222]">{{movies.genre}}</span>
      <span  @click="seeAllFilms" v-if="movies?.films !== undefined" :class="movies?.films[0].level === activeHardness? activeHardness ===0 ? 'tw-text-[#F9965F]' : activeHardness ===1 ? 'tw-text-[#662483]' : activeHardness === 2 ?  'tw-text-[#70BAEF]' : 'tw-text-[#FEB549]' : ''" class="tw-text-[13px]">See all</span>
    </div>
    <div class="tw-w-full tw-grid  tw-gap-y-4 tw-overflow-x-scroll tw-grid-cols-2  tw-gap-x-4">
      <one-movie-container  v-for="(movie,index) in movies?.films" :movie="movie" :key="index"/>
    </div>
  </div>
</template>

<script>
import OneMovieContainer from "@/components/common/oneMovieContainer.vue";

export default {
  name : "TopMovieContainer",
  components: {OneMovieContainer},
  props:{
    movies:{},
    activeHardness : {}
  },
  watch:{

  },
  methods:{
    seeAllFilms(){
      console.log(this.movies);
      this.$router.push(`category/${this.movies.genre_id}`)
    }
  }


}

</script>


<style scoped>

</style>