import {toEnglishNumber, toFarsiNumber} from "@/helpers/fundamentalHelper";

export var rules = {
    methods:{
        phoneNumberOrEmailRules(value){
            let otpRule = ''
            value = toEnglishNumber(value)
            if (value === null) {
                value = parseInt(null)
            }

            if (!isNaN(value) && value !== null) {
                let v = toEnglishNumber(value);
                if (!isNaN(v) && v.length === 11 && v.slice(0, 2) === "09" && /^\d+$/.test(v) === true)
                    otpRule = true;
                else if (v.slice(0, 2) !== "09") otpRule = "شماره ی وارد شده صحیح نیست";
                else if (v.length !== 11) otpRule = "شماره ی موبایل ۱۱ رقمی است";
            } else {
                if (value === "" || value === null) {
                    otpRule = "وارد کردن این بخش اجباری است"
                } else {
                    otpRule = !value ||
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
                        "ایمیل وارد شده صحیح نیست"
                }
            }
            return otpRule;
        },
        min_maxRule(value,min){

            if((value === '' && /^(\d+(\.\d+)?)$/.test(min)) || (min === ''&& /^(\d+(\.\d+)?)$/.test(value)) || (value === '' && min === '') || (value === null && min=== null) || (parseFloat(value) >= parseFloat(min) && (/^(\d+(\.\d+)?)$/.test(min) && /^(\d+(\.\d+)?)$/.test(value)))){
                return true
            }
            return 'max is less that min number'

        },
        requireRule(value , isEnglish = false){
            if(Array.isArray(value)){
                if(value?.length===0){

                    return isEnglish ? 'This Field should not be empty' : 'وارد کردن این بخش اجباری است'
                }
            }
            return  !!value || (isEnglish ? 'This Field should not be empty' :'وارد کردن این بخش اجباری است')
        },

        maxContent(value,maxValue,isEnglish = false){
            if(value?.length<=maxValue){
                return true
            }  else{

                return isEnglish ? `The text must be less than ${maxValue} characters` : ` متن وارد شده باید کمتر از ${toFarsiNumber(maxValue)}   حرف باشد`
            }
        },
        largeMaxContent(value){
            if(value.length<=5000){
                return true
            }  else{
                return 'متن وارد شده باید کمتر از ۵۰۰۰ حرف باشد'
            }
        },
        verifyVideo(inputFile){
            var regExp =  /\.(mp4|MP4 |MKV | WMV |MOV|M4V|mkv|wmv|mov|m4v)$/
            if(!regExp.test(inputFile.name)){
                return ' فرمت ویدیو اشتباه شده است'
            }
            return  true
        },
        verifyImage(inputFile)
        {
            console.log(inputFile)
            if(inputFile?.length===0){
                return true
            }
            if(!inputFile || inputFile?.length > 0){
                if(Array.isArray(inputFile)){
                    for (let i = 0; i < inputFile.length; i++) {
                        console.log('this.verifyImage(inputFile[i])',this.verifyImage(inputFile[i]))
                        if(this.verifyImage(inputFile[i])!==true){
                            return 'لطفا از فرمت jpg,jpeg,png استفاده کنید.'
                        }
                    }
                }
                return true
            }
            var regExp =  /\.(jpg|jpeg|png)$/
            if(!regExp.test(inputFile?.name)){
                return 'لطفا از فرمت jpg,jpeg,png استفاده کنید.'
            }
            if(!this.checkImageSize(inputFile)){
                return 'اندازه تصویر باید کمتر از ۵ مگابایت باشد'
            }
            return true;
        },
        verifyPdf(inputFile)
        {
            console.log(inputFile)
            if(!inputFile){
                return true
            }
            if(inputFile?.length===0){
                return true
            }
            var regExp =  /\.(pdf|PDF|Pdf)$/
            if(!regExp.test(inputFile.name)){
                return 'لطفا از فرمت pdf استفاده کنید.'
            }
            if(!this.checkPdfSize(inputFile)){
                return 'اندازه pdf باید کمتر از ۵۰ مگابایت باشد'
            }
            return true;
        },
        verifyLatex(inputFile)
        {
            console.log(inputFile)
            if(!inputFile){
                return true
            }
            if(inputFile?.length===0){
                return true
            }
            var regExp =  /\.(txt)$/
            if(!regExp.test(inputFile.name)){
                return 'لطفا از فرمت txt استفاده کنید.'
            }
            if(!this.checkLatexSize(inputFile)){
                return 'اندازه لتکس باید کمتر از ۵۰ مگابایت باشد'
            }
            return true;
        },
        verifyBigoraphy(inputFile)
        {
            var regExp =  /\.(txt)$/
            if(!regExp.test(inputFile.name)){
                return 'لطفا از فرمت txt استفاده کنید.'
            }
            if(!this.checkLatexSize(inputFile)){
                return 'اندازه لتکس باید کمتر از ۵۰ مگابایت باشد'
            }
            return true;
        },
        verifyAudio(inputFile)
        {
            console.log(inputFile)
            if(!inputFile){
                return true
            }
            if(inputFile?.length===0){
                return true
            }
            var regExp =  /\.(mp3)$/
            if(!regExp.test(inputFile.name)){
                return 'لطفا از فرمت mp3 استفاده کنید.'
            }
            if(!this.checkAudioSize(inputFile)){
                return 'اندازه صوت باید کمتر از ۵۰ مگابایت باشد'
            }
            return true;
        },
        checkImageSize(inputFile){
            return  (inputFile?.size / 1048576) < 5000000
        },
        checkPdfSize(inputFile){
            return  (inputFile?.size / 1048576) < 50000000
        },
        checkLatexSize(inputFile){
            return  (inputFile?.size / 1048576) < 50000000
        },
        checkAudioSize(inputFile){
            return  (inputFile?.size / 1048576) < 50000000
        },
        percentRule(value , isEnglish = false , canEmpty = false) {
            // if (!value.trim()) return true;
            value= toEnglishNumber(value)
            if (!isNaN(parseFloat(value))) {
                if (parseFloat(value) >= 0 && parseFloat(value) <= 100) {
                    return true;
                } else {
                    if (parseFloat(value) < 0) {
                        return isEnglish ? "The percentage must be greater than 0" :"درصد باید بیشتر از ۰ باشد";
                    }
                    if (parseFloat(value) > 100) {
                        return isEnglish ? "The percentage must be less than 100" : "درصد باید کمتر از ۱۰۰ باشد";
                    }
                    return isEnglish ?  "The entered number is not in the range" :"عدد وارد شده بین بازه نیست";
                }
            } else if (isNaN(parseFloat(value))) {

                if (value === "" || value === null) {
                    if(canEmpty){
                        return true
                    }
                    return isEnglish ? "The value cannot be empty." : "مقدار نمی‌تواند خالی باشد.";
                }
                return isEnglish ? "The entered value is not a number" :"مقدار وارد شده عدد نمی باشد";
            }
        },
        rangeRules(value , min = 2 , max = 10 , isEnglish = false ){


            if( value.length >= min && value.length <=  max){
                return true
            }else{
                return isEnglish ?  `The length of entered value is not between ${min} and ${max}` :`مقدار وارد شده بین ${min} و ${max} نیست`;
            }


        },
        emailRules(value) {
            if(!value){
                return "This Field is required."
            }
                return   !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Entered Email is nor correct.'
        },
        nationalCodeRule(value) {
            let v = toEnglishNumber(value);
            if (!isNaN(parseInt(v)) && v.length === 10) {
                return true;
            }
            if (v === "") return "وارد کردن این بخش اجباری است";
            if (isNaN(parseInt(v))) return "کدملی باید عدد باشد";
            if (v.length !== 10) return "کدملی ۱۰ رقمی است";
        },
        postalCodeRule(value) {
            let v = toEnglishNumber(value);
            if (!isNaN(parseInt(v)) && v.length === 10) {
                return true;
            }
            if (v === "") return "وارد کردن این بخش اجباری است";
            if (isNaN(parseInt(v))) return "کدپستی باید عدد باشد";
            if (v.length !== 10) return "کدپستی ۱۰ رقمی است";
        },
        phoneRule(value){
            let v = toEnglishNumber(value);
            if (!isNaN(parseInt(v)) && v.length === 11 && v.slice(0, 2) === "09" && /^\d+$/.test(v) === true)
                return true;
            if (isNaN(parseInt(v))) return "شماره ی وارد شده عدد نیست";
            if (/^\d+$/.test(v) === false) return "شماره ی وارد شده عدد نیست"

            if (v.slice(0, 2) !== "09") return "شماره ی وارد شده صحیح نیست";
            if (v.length !== 11) return "شماره ی موبایل ۱۱ رقمی است";
        },
        retypePassRule(password , newPassword) {
            if (newPassword && password === newPassword) {
                return true;
            }
            if (!newPassword) {
                return "This Field is Required";
            }
            if (password !== newPassword && password !== "") {
                return "Entered password is not same";
            }
        },
        retypeEmail(email , newEmail) {
            if (newEmail && email === newEmail) {
                return true;
            }
            if (!newEmail) {
                return "وارد کردن این بخش الزامی است";
            }
            if (email !== newEmail && email !== "") {
                return "ایمیل وارد شده یکسان نمی‌باشد.";
            }
        },
        numberRules(value ,isEnglish = false) {
            let v = toEnglishNumber(value);
            if (!isNaN(v)) {
                return true
            } else {
                return isEnglish ? 'Input is not a number' :  'مقدار وارد شده باید عدد باشد'
            }
        },
        verifyPassword(inputText)
        {
            var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

            return decimal.test(inputText) ? true : "رمز عبو وارد شده قوی نیست";
        },
        ShebaRule(value) {
            if (value === null) return "وارد کردن این بخش اجباری است";
            let v = toEnglishNumber(value);
            if (
                !isNaN(parseInt(v.slice(2, v?.length))) &&
                v !== "" &&
                v?.length === 26 &&
                v.slice(0, 2) === "IR" &&
                /^\d+$/.test(v.slice(2, v?.length))
            ) {
                return true;
            }
            if (/^\d+$/.test(v.slice(2, v?.length)) === false) return "شماره وارد شده صحیح نیست"
            if (v === "") return "وارد کردن این بخش اجباری است";
            if (v.slice(0, 2) !== "IR") return "شماره شبا با IR شروع می شود";
            if (isNaN(parseInt(v.slice(2, v?.length))))
                return "شماره شبا اشتباه وارد شده است";
            if (v?.length !== 26) return "شماره شبا شامل ۲۶ حرف می باشد";
        },
        cardRule(value) {
            let v = toEnglishNumber(value);
            if (!isNaN(parseInt(v)) && v !== "" && v?.length === 16 && /^\d+$/.test(v)) {
                return true;
            }
            if (/^\d+$/.test(v) === false) return "شماره وارد شده صحیح نیست"

            if (isNaN(parseInt(v))) return "شماره کارت اشتباه وارد شده است";
            if (v?.length !== 16) return "شماره کارت شامل ۱۶ حرف می باشد";
        },
    }
}

