<template>
  <div class="tw-bg-[#662483] tw-flex  tw-h-screen tw-flex-col tw-gap-y-6">
    <div class="tw-flex tw-gap-x-4 tw-pt-6 tw-px-6">
      <v-icon color="#F5F7F6" size="24" @click="back">mdi-chevron-left</v-icon>
      <span class="tw-text-2xl tw-text-[#F5F7F6]">My Word Bank</span>
    </div>
    <search-box class="tw-px-6" placeholder="Search" background-color="#8C5BA2" :dark="true" magnify-color="#F5F7F6" @search="search" />
    <div class="tw-flex tw-flex-col tw-overflow-hidden tw-px-6">
      <v-tabs class="tabs" v-model="currentTab" background-color="transparent" hide-slider :show-arrows="false"   >
        <v-tab style="font-size: 12px; min-width: 0" :style="currentTab ===0 ? 'color : #F5F7F6': 'color : #C0D5EC' "  class="tw-normal-case">
          <div class="tw-relative tw-h-full tw-flex tw-w-full tw-justify-center tw-items-center" >
            <span  :class="currentTab === 0 ? 'tw-text-white' : 'tw-text-[#B291C1]'">All</span>
            <div class="tw-w-1 tw-absolute tw-top-10 tw-bottom-0 tw-h-1 tw-bg-white tw-rounded-full" v-if="currentTab === 0"></div>
          </div>
        </v-tab>
        <v-tab style="font-size: 12px; min-width: 0" :style="currentTab ===1 ? 'color : #F5F7F6': 'color : #C0D5EC' "  class="tw-normal-case">
          <div class="tw-relative tw-h-full tw-flex tw-w-full tw-justify-center tw-items-center" >
              <div class="tw-flex tw-gap-x-2">
                <img :src="currentTab === 1 ? this.whiteBookmarked : this.light_purple_bookmarked " alt="">
                <span  :class="currentTab === 1 ? 'tw-text-white' : 'tw-text-[#B291C1]'">Bookmarked</span>

              </div>
              <div class="tw-w-1 tw-absolute tw-top-10 tw-bottom-0 tw-h-1 tw-bg-white tw-rounded-full" v-if="currentTab ===1"></div>
          </div>
        </v-tab>
        <v-tab style="font-size: 12px; min-width: 0" :style="currentTab ===2 ? 'color : #F5F7F6': 'color : #C0D5EC' "  class="tw-normal-case">
          <div class="tw-relative tw-h-full tw-flex tw-w-full tw-justify-center tw-items-center" >
            <div class="tw-flex tw-gap-x-2">
              <img :src="currentTab === 2 ? this.white_hide : this.light_purple_hide " alt="">
              <span  :class="currentTab === 2 ? 'tw-text-white' : 'tw-text-[#B291C1]'"> known</span>
            </div>
            <div class="tw-w-1 tw-absolute tw-top-10 tw-bottom-0 tw-h-1 tw-bg-white tw-rounded-full" v-if="currentTab === 2"></div>
          </div>
        </v-tab>
        <v-tab style="font-size: 12px; min-width: 0" :style="currentTab ===3 ? 'color : #F5F7F6': 'color : #C0D5EC' "  class="tw-normal-case">
          <div class="tw-relative tw-h-full tw-flex tw-w-full tw-justify-center tw-items-center" >
            <div class="tw-flex tw-gap-x-2">
              <img :src="currentTab === 3 ? this.white_learned : this.light_purple_learned " alt="">
              <span  :class="currentTab === 3 ? 'tw-text-white' : 'tw-text-[#B291C1]'">Learned</span>
            </div>
            <div class="tw-w-1 tw-absolute tw-top-10 tw-bottom-0 tw-h-1 tw-bg-white tw-rounded-full" v-if="currentTab === 3"></div>
          </div>
        </v-tab>
      </v-tabs>
    </div>

    <div class="tw-flex tw-flex-1 tw-gap-y-4 tw-overflow-y-scroll tw-h-12 tw-bg-white tw-flex-col tw-p-6 tw-gap-y-6" style="border-radius: 24px 24px 0 0"  >
      <v-progress-circular v-if="loading" indeterminate size="20"  class="tw-mx-auto tw-my-auto" color="#1c78f2"/>
      <FilmOneWord :current-tab.sync="currentTab" :userword="true" @fetchData="fetchData" v-for="(word,index) in userWordBankComputed" :key="index" :word="word" v-else></FilmOneWord>
    </div>
  </div>
</template>

<script>
import SearchBox from "@/components/common/searchBox.vue";
import FilmOneWord from "@/components/FilmOneWord.vue";
import {mapActions, mapGetters} from "vuex";
import white_learned from "@/assets/svg/white_learned.svg"
import white_hide from "@/assets/svg/white_hide.svg"
import light_purple_hide from "@/assets/svg/light_purple_hide.svg"
import whiteBookmarked from "@/assets/svg/white_bookmarked.svg"
import light_purple_bookmarked from "@/assets/svg/light_perpule_bookmarked.svg"
import light_purple_learned from "@/assets/svg/light_purple_learned.svg"
export default {
  name : 'UserWords',
  data:()=>({
    currentTab : 0,
    whiteBookmarked,
    white_hide,
    light_purple_hide,
    light_purple_bookmarked,
    light_purple_learned,
    loading : false,
    searchText : '',
    white_learned,


  }),
  async mounted() {
    this.loading = true
    await this.fetchUserWords(this.payload)
    this.loading = false
  },
  watch:{
    async currentTab(){
      this.loading = true
      await this.fetchUserWords(this.payload)
      this.loading = false
    }
  },
  methods:{
    ...mapActions({
      fetchUserWords : "userprofile/fetchUserWords"
    }),
    back(){
      this.$router.push("/")
    },
    async fetchData(){
      this.loading = true
      await this.fetchUserWords(this.payload)
      setTimeout(()=>{
        this.loading = false
      },2000)
    },
    async search(message){
      this.searchText = message
      await this.fetchData()
    }
  },
  computed:{
    ...mapGetters({
      getUserWords : "userprofile/getUserWords"
    }),
    userWordBankComputed(){
      return this.getUserWords
    },
    payload(){
      return {
        search : this.searchText,
        //todo check this type for call api
        type : this.currentTab === 0 ? null : this.currentTab === 1 ? 'bookmarked'  : this.currentTab === 2 ?  'got_it' : 'known'
      }
    }
  },
  components: { FilmOneWord,SearchBox}
}

</script>

<style>
.theme--light.v-tabs .v-tab--active:hover::before, .theme--light.v-tabs .v-tab--active::before{
  opacity: 0!important;
}
.tabs .v-item-group .v-slide-group__prev{
  display: none !important;
}

</style>