<template>
  <div class="tw-flex tw-flex-col tw-w-full tw-p-6 tw-bg-white tw-h-screen  ">
    <div class="tw-items-start tw-flex tw-mb-8 ">
      <v-icon size="24px" color="#0A040D" @click="goBack">mdi-chevron-left</v-icon>
    </div>
    <span class="tw-text-xl tw-font-medium tw-text-[#222222]" v-text="state === 0 ? 'Change Password' : state ===1 ? 'Forget Password?' : state === 2 ? 'Check Your Email' : 'Change Password' ">Change Password</span>
    <span class="tw-text-sm tw-font-normal tw-text-[#888888] tw-mt-3" v-text="state === 0 ? 'Your password must be at least 8 characters long.' : state ===1 ? 'Enter Your Email.' : state === 2 ? `We sent a code to ${forgetPassEmail}` : 'Your password must be at least 8 characters long.' "></span>
    <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-y-2 tw-mt-8" v-if="state === 0">
      <span class="tw-text-[#888888] tw-text-sm tw-font-medium">Old Password</span>
      <v-text-field height="48px"  outlined color="#222222" style="width: 100%;border-radius: 8px" type="password"  dense hide-details full-width v-model="oldPassword"  />
    </div>
    <span v-if="state === 0" class="tw-text-[#662483] tw-text-xs tw-font-medium tw-ml-auto tw-mt-3" @click="changeState(1)">Forgot Password?</span>
    <v-form lazy-validation ref="passwordRef" v-model="passwordValidation" @submit="e=>e.preventDefault()">
      <div class="tw-flex tw-flex-col" v-if="state === 0">
        <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-y-2 tw-mt-8">
          <span class="tw-text-[#888888] tw-text-sm tw-font-medium">New Password</span>
          <v-text-field height="48px"  outlined color="#222222" style="width: 100%;border-radius: 8px" type="password"  dense  full-width v-model="newPassword" :rules="[requireRule(newPassword,true),rangeRules(newPassword,8,16,true)]" />
        </div>
        <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-y-2 ">
          <span class="tw-text-[#888888] tw-text-sm tw-font-medium">Confirm Password</span>
          <v-text-field height="48px"  outlined color="#222222" style="width: 100%;border-radius: 8px" type="password" dense  full-width v-model="repeatPassword"  :rules="[retypePassRule(newPassword,repeatPassword)]" />
        </div>
        <v-btn color="#662483" height="48px" elevation="0" style="border-radius: 8px" width="100%" @click="changePasswordFunction" :disabled="!passwordValidation">
          <span class="tw-text-white tw-normal-case">Change Password</span>
        </v-btn>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-y-6" v-if="state === 1">
        <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-y-2 tw-mt-8">
          <span class="tw-text-[#888888] tw-text-sm tw-font-medium">Email</span>
          <v-text-field height="48px"  outlined color="#222222" style="width: 100%;border-radius: 8px"  dense  full-width v-model="forgetPassEmail" :rules="[requireRule(forgetPassEmail,true),emailRules(forgetPassEmail,true)]" />
        </div>
        <v-btn color="#662483" height="48px" elevation="0" style="border-radius: 8px" width="100%" @click="continueFn" :disabled="!passwordValidation">
          <span class="tw-text-white tw-normal-case">Continue</span>
        </v-btn>
      </div>
      <div class="tw-flex tw-flex-col tw-mt-8" v-if="state === 2">
        <v-otp-input length="5" type="number" class="otpInput" v-model="emailOtp" ref="otpInput" @finish="continueFnForResetPass"/>
        <span class="tw-text-sm tw-font-normal tw-mt-8 tw-mb-10 tw-text-[#888888]">Didn’t get a code? <span class="tw-text-[#222222] tw-text-sm tw-font-medium" @click="resendCode">Click to resend</span></span>
        <v-btn color="#662483" height="48px" elevation="0" style="border-radius: 8px" width="100%" @click="continueFnForResetPass" :disabled="!passwordValidation">
          <span class="tw-text-white tw-normal-case">Continue</span>
        </v-btn>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-y-0" v-if="state === 3">
        <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-y-2 tw-mt-8">
          <span class="tw-text-[#888888] tw-text-sm tw-font-medium">New Password</span>
          <v-text-field height="48px"  outlined color="#222222" style="width: 100%;border-radius: 8px"  dense full-width v-model="forgetPassNewPassword" :rules="[requireRule(forgetPassNewPassword,true),rangeRules(forgetPassNewPassword,8,16,true)]"  />
        </div>
        <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-y-2 ">
          <span class="tw-text-[#888888] tw-text-sm tw-font-medium">Confirm Password</span>
          <v-text-field height="48px"  outlined color="#222222" style="width: 100%;border-radius: 8px"  dense  full-width v-model="forgetPassRetypePassword" :rules="[requireRule(forgetPassRetypePassword,true), retypePassRule(forgetPassNewPassword,forgetPassRetypePassword,true)]"  />
        </div>
        <v-btn color="#662483" height="48px" elevation="0" style="border-radius: 8px" width="100%" @click="changePassword" :disabled="!passwordValidation" class="tw-mt-6">
          <span class="tw-text-white tw-normal-case">Change Password</span>
        </v-btn>
      </div>
    </v-form>
  </div>


</template>

<script>

import {rules} from "@/mixins/rules";
export default {
  name : "ChangePassword",
  mixins:[rules],
  props:{
    initialState:{
      default : null
    }
  },
  data:()=>({
    oldPassword : '',
    newPassword : '',
    repeatPassword : '',
    passwordValidation : false,
    state : 0,
    forgetPassEmail : '',
    emailOtp : null,
    forgetPassNewPassword  : '',
    forgetPassRetypePassword  : ''
  }),
  watch:{
    state(){
      this.$refs.passwordRef.resetValidation()
    }
  },
  methods:{
    changeState(value){
      this.state = value
    },
    continueFnForResetPass(){
      this.passwordValidation = this.$refs.passwordRef.validate()
      if(this.passwordValidation){
        //Todo call api for verify otp

        this.state = 3
      }
    },
    resendCode(){
      this.$refs.otpInput.otp = [null,null,null,null,null] // to reset the otp value, it has reset function but dont work for me

      //Todo call api for resend otp
    },
    changePassword(){


      this.passwordValidation = this.$refs.passwordRef.validate()
      if(this.passwordValidation){
        //Todo call api for change password of user
        this.$router.push("/profile")
      }
    },
    goBack(){
      if(this.state === 0){
        this.$router.push("/profile")
      }
      else {
        this.state -=1
      }
      console.log(this.state);
    },
    continueFn(){
      this.passwordValidation = this.$refs.passwordRef.validate()
      if(this.passwordValidation){
        this.state = 2
        //Todo call api for send otp code
      }
    },
    changePasswordFunction(){
      this.passwordValidation = this.$refs.passwordRef.validate()
      if(this.passwordValidation){
        //Todo call api for change password
        this.goBack()
      }

    }

  },
  mounted() {
    if(this.initialState){
      this.state = this.initialState
    }
  }
}

</script>
<style scoped>

</style>