import { render, staticRenderFns } from "./allCommentOneComment.vue?vue&type=template&id=5a18d201&scoped=true"
import script from "./allCommentOneComment.vue?vue&type=script&lang=js"
export * from "./allCommentOneComment.vue?vue&type=script&lang=js"
import style0 from "./allCommentOneComment.vue?vue&type=style&index=0&id=5a18d201&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a18d201",
  null
  
)

export default component.exports