<template>
    <div class="tw-flex tw-gap-x-4 tw-overflow-scroll scrollbar-hide tw-w-full">
      <img v-for="(item,index) in getUserTasks.results" :key="index" class="tw-w-full"  :src="item.src || Banner" alt="">
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Banner from "@/assets/svg/Banner 2.svg"

export default {
  name : "TaskSlider",
  data:()=>({
      Banner
  }),
  methods:{
    ...mapActions({
      fetchUserTasks : "userprofile/fetchUserTasks"
    })
  },
  async mounted() {
    await this.fetchUserTasks()
  },
  computed:{
    ...mapGetters({
      getUserTasks : "userprofile/getUserTasks"
    })

  }
}

</script>

<style>
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge, and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>