<template>
  <div class="tw-flex tw-flex-col tw-gap-y-4">
    <div class="tw-flex tw-justify-between">
      <span class="tw-font-medium">You might like it</span>
      <span class="tw-text-sm tw-font-medium" :class="level === 1 ? 'tw-text-[#662483]' : level === 2 ? 'tw-text-[#70BAEF]' : 'tw-text-[#FEB549]'" @click="seeAllVideos">See All</span>
    </div>
    <div class="tw-grid tw-grid-cols-2 tw-gap-4 ">
      <one-movie-container v-for="(video,index) in similarVideos" :key="index"  :movie="video"/>
    </div>
  </div>
</template>

<script>
import OneMovieContainer from "@/components/common/oneMovieContainer.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name : "SimilarVideos",
  components: {OneMovieContainer},
  async mounted() {
    await this.fetchSimilarVideos({level : this.level})
  },
  props:{
    level : {}
  },
  watch:{
    level(newValue){
       this.fetchSimilarVideos({level : newValue})

    }
  },
  computed:{
    ...mapGetters({
      getSimilarVideos : "SimilarVideos/getSimilarVideos"
    }),
    similarVideos(){
      if(this.getSimilarVideos?.results !== undefined)
        return this.getSimilarVideos?.results
      return []
    },
  },
  methods:{
    ...mapActions({
      fetchSimilarVideos : "SimilarVideos/fetchSimilarVideos"
    }),
    seeAllVideos(){
      this.$emit("seeAll")
    }
  }
}

</script>

<style scoped>

</style>