import Vue from 'vue'
import VueRouter from 'vue-router'
import words from "@/components/words";
import home from "@/components/home/home.vue";
import OneGenre from "@/components/genre/oneGenre.vue";
import oneFilm from "@/components/FIlm/oneFilm.vue";
import UserWords from "@/components/home/UserWords.vue";
import userWordsChat from "@/components/home/userWordsChat.vue";
import allComments from "@/components/comments/allComments.vue";
import login from "@/components/login/login.vue";
import Profile from "@/components/Profile/Profile.vue";
import ChangePassword from "@/components/Profile/ChangePassword.vue";
Vue.use(VueRouter)

const routes = [
  {
    path: '/translate/:film_id',
    name: 'translate',
    component: words
  },
  {
    path : '/',
    name : 'home',
    component: home
  },
  {
    path : '/login',
    name : 'login',
    component: login
  },
  {
    path : '/password',
    name : 'changePassword',
    component: ChangePassword
  },
  {
    path : '/profile',
    name : 'profile',
    component: Profile
  },
  {
    path : '/words',
    name : 'userWord',
    component: UserWords
  },
  {
    path : '/words/chat/:id',
    name : 'userWordChat',
    component: userWordsChat
  },
  {
    path : "/category/:id",
    name : 'category',
    component: OneGenre
  },
  {
    path : "/film/:id",
    name : 'oneFilm',
    component: oneFilm
  },
  {
    path : "/film/:id/comments",
    name : 'allComments',
    component: allComments
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
