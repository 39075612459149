<template>
  <div class="tw-flex tw-flex-col tw-p-6">

    <UserTopBar>
      <template>
        <div class="tw-flex tw-gap-x-4">
          <v-icon color="#0B131A" class="tw-w-6 tw-h-6 " @click="backToHome">mdi-chevron-left</v-icon>
          <!--            <v-icon color="#0B131A" class="tw-w-6 tw-h-6">mdi-bookmark-outline</v-icon>-->
        </div>
      </template>
    </UserTopBar>
    <div class="tw-mt-8 tw-flex tw-flex-col tw-gap-y-1">
      <span class="tw-text-xl tw-font-semibold">Comments</span>
      <span class="tw-text-sm tw-text-[#CBCBCB]">{{getCurrentFilm?.comments?.length || 256}} user commented</span>
    </div>
    <div class="tw-flex tw-flex-col tw-gap-y-6 tw-mt-6">
      <all-comment-one-comment  v-for="(comment,index) in sample_comments" :key="index" :comment="comment"/>
    </div>
  </div>
</template>

<script>
import UserTopBar from "@/components/common/UserTopBar.vue";
import {mapActions, mapGetters} from "vuex";
import AllCommentOneComment from "@/components/comments/allCommentOneComment.vue";

export default {
  name : "allComments",
  components: { AllCommentOneComment, UserTopBar},
  data:()=>({
    sample_comments : [
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfglsdfjsdfjgkljsdfg",is_liked : true , is_disliked : false},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfglsdfjsdfjgkljsdfg",is_liked : false , is_disliked : false},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfglsdfjsdfjgkljsdfg",is_liked : true , is_disliked : false},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfglsdfjsdfjgkljsdfg",is_liked : false , is_disliked : true},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfglsdfjsdfjgkljsdfg",is_liked : true , is_disliked : false},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfglsdfjsdfjgkljsdfg",is_liked : false , is_disliked : false},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfglsdfjsdfjgkljsdfg",is_liked : true , is_disliked : false},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfglsdfjsdfjgkljsdfg",is_liked : true , is_disliked : false},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfglsdfjsdfjgkljsdfg",is_liked : false , is_disliked : true},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfglsdfjsdfjgkljsdfg",is_liked : true , is_disliked : false},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfglsdfjsdfjgkljsdfg",is_liked : true , is_disliked : false},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg lskdjfg s;ldfgj skldfgjkjfpwerg wiehgksn 'djg'poetir pqoej gkh;dfgn,s lsdfjsdfjgkljsdfglsdfjsdfjgkljsdfg",is_liked : true , is_disliked : false},
      {author : 'yousefpour',created_at : 1698452012,text : "sdkjfalksjdfajsdfkjasdfjadlkgjsdkfgj lsdg sl;dfjg "}]
  }),
  mounted() {

  },
  computed:{
    ...mapGetters({
      getCurrentFilm : "movies/getCurrentFilm"
    })
  },
  methods:{
    ...mapActions({
      fetchCurrentFilm : "movies/fetchCurrentFilm"
    }),

    backToHome(){
      this.$router.push(`/film/${this.$route.params.id}`)
    }
  }
}
</script>


<style scoped>

</style>