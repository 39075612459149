<template>
  <div class="tw-flex tw-gap-x-3 tw-overflow-x-auto scrollbar-hide">
    <div v-for="(level , index) in levels" class="tw-px-4 tw-py-2 tw-rounded-[33px] " style="white-space: nowrap"
           :key="index" :class="index === activeIndex ? activeIndex ===0 ? 'tw-bg-[#FEF4EF]' : activeIndex ===1 ? 'tw-bg-[#EFE9F2]' : activeIndex === 2 ?  'tw-bg-[#F1F8FD]' : 'tw-bg-[#ffF4E3]':''" @click="changeHardness(index)">
      <span :class="index === activeIndex? activeIndex ===0 ? 'tw-text-[#F9965F]' : activeIndex ===1 ? 'tw-text-[#662483]' : activeIndex === 2 ?  'tw-text-[#70BAEF]' : 'tw-text-[#FEB549]' : ''" class="tw-text-[13px]" v-text="level" />
    </div>

  </div>
</template>

<script>
export default {
  name : "hardnessLevel",
  data:()=>({
    levels : ['All','Elementary','Intermediate','Advanced']
  }),
  props : {
    activeIndex : {
      default :0,
    }
  },
  methods:{
    changeHardness(index){
      this.$emit("changeActiveHardness" , index )
    }
  }

}

</script>

<style scoped>

</style>