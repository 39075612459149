<template>
  <div class="tw-flex tw-flex-col tw-px-4 tw-pb-4 tw-pt-3 tw-gap-y-3 tw-bg-white tw-h-full" style="border-radius: 12px;max-width: 65%;">
    <div class="tw-flex tw-justify-between tw-items-center">
      <span class="tw-text-sm tw-font-medium tw-text-[#222222]">{{comment.author}}</span>
      <span class="tw-text-fs10 tw-text-[#888888]">{{convertTimeToSuitableTime(comment.created_at)}}</span>
    </div>
    <div class="commentText" >
      {{comment.text}}
    </div>
  </div>
</template>

<script>
import {dateHelper} from "@/mixins/dateHelpers";

export default {
  name : "OneFilmComments",
  mixins :[dateHelper],
  props:{
    comment:{}
  }
}
</script>


<style scoped>
.commentText{
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>