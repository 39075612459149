

export function findObjectWithKey(array,key,value){
    return array.find(x => x[key] === value);
}

export function  findIndexWithKey(array, key , value){
    return array.findIndex(x => x[key] === value);
}
export function  findIndexWithValue(array , value){
    return array.findIndex(x => x === value);
}

export function toFarsiNumber(n) {
    if (n !== undefined && n !== null)
        return n
            .toString()
            .split("")
            .map((x) => x.replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]))
            .join("");
}

export function toEnglishNumber(n) {
    if (!n) {
        return n;
    }
    return n
        .toString()
        .split("")
        .map((x) => x.replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d)))
        .join("");
}

export function generateMatrix(n) {
    let matrix = [];
    for (let i = 0; i < n; i++) {
        let row = [];
        for (let j = 0; j < n; j++) {
            let value = Math.round(Math.random() * 100) / 100;
            row.push(value);
        }
        matrix.push(row);
    }
    return matrix;
}
export function addUnit(e , isEnglish=true) {
    if (e < 1000) {
        return isEnglish ? e  : toFarsiNumber(e);
    } else if (e >= 1000 && e < 1000000) {
        return isEnglish ? Math.floor((e / 1000) * 100) / 100 + " k"  : toFarsiNumber(Math.floor((e / 1000) * 100) / 100) + " هزار ";
    } else {
        return isEnglish ?  Math.floor((e / 1000000) * 100) / 100 + " m" : toFarsiNumber(Math.floor((e / 1000000) * 100) / 100) + "میلیون"
    }
}

export  function interpolateColor(color1, color2, steps) {
    let stepFactor = 1 / (steps - 1);
    let interpolatedColorArray = [];

    let color1Array = color1.match(/\d+/g).map(Number);
    let color2Array = color2.match(/\d+/g).map(Number);

    for (let i = 0; i < steps; i++) {
        let color = color1Array.map((c, j) => Math.round(c + (color2Array[j] - c) * i * stepFactor));
        interpolatedColorArray.push(`rgb(${color.join()})`);
    }

    return interpolatedColorArray;
}

