<template>
  <div id="chatBox" :class="typeOfShow !== 1 ? 'tw-grid tw-grid-rows-[48px_50px]' : 'tw-grid tw-grid-rows-[1fr,50px] tw-h-screen'"  class="tw-w-full ">

    <div ref="messageContainer" id="messageContainer" style="overflow-y: scroll !important;" class=" tw-w-full tw-flex tw-flex-col  tw-h-[90%] tw-px-6 tw-pt-6 tw-pb-2 tw-gap-y-3 tw-overflow-y-auto tw-overflow-x-hidden " :style="typeOfShow === 1 ? 'height : calc(100%-50px)' : ''" :class="typeOfShow === 1 ? '' : 'tw-max-h-full'">
      <div v-for="(message, index) in messages" :key="index" :style="`direction : ltr; maxWidth : ${message.is_guide ? '100%' : '85%'}`" :class="[message.owner === 'user' ? 'tw-bg-[#F5F7F6] tw-ml-auto tw-mr-0 tw-rounded-48 tw-px-4 tw-py-4 ' : 'tw-bg-white tw-mr-auto tw-text-left']" class="tw-leading-4 " style="height: fit-content;width: fit-content;overflow-wrap: break-word">
        <span v-if="!message.is_guide" class="tw-text-fs10 tw-text-black">{{ message?.content }}</span>
        <div v-else-if="message.is_guide && showGuidMessage" class="tw-flex tw-px-2  tw-flex-col tw-items-start tw-bg-[#F5F7F6] tw-gap-y-3 tw-rounded-[12px]" style="direction: ltr;max-width: 100%;width: 100%">
          <div v-for="(item, index) in getCurrentChat?.item_type === 'EduPoint' ? getSampleQuesEdu : getSampleQuesFilm" :key="index" class="tw-flex tw-gap-x-2">
            <img :src="`https://vidipen.com/${item.avatar}`" alt="">
            <span class="tw-text-black tw-text-fs10" @click="changeTextFieldValue(item,true)">{{item?.content}}</span>
          </div>
        </div>
      </div>
    </div>

<!--    <chat-text-box  />-->
    <div class="tw-h-12 tw-flex tw-justify-between tw-gap-x-3 tw-px-6 " style="direction: rtl">
      <v-text-field id="chatBotTextField" ref="chatBotTextField" hide-details  @keyup.enter="addMessage" placeholder="Movie Chatbot" outlined dense class="tw-w-1/2 tw-text-xs" v-model="textMessage" background-color="#F5F7F6" style="border-radius: 41px;direction: ltr" color="#8E8E8E">
        <template #append>
          <v-icon :disabled="textMessage === '' || !response_is_finished" :color="textMessage === '' ? '#0B131A' : '#6196CF'" class="tw-cursor-pointer" @click="addMessage">mdi-arrow-up-circle</v-icon>
        </template>
      </v-text-field>
      <div class="tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#EFE9F2] tw-flex tw-justify-center tw-items-center tw-cursor-pointer" @click="changeGuidance">
        <v-icon color="#662483" v-if="!activeGuide">mdi-help-circle-outline</v-icon>
        <v-icon v-else color="#0B131A">mdi-close</v-icon>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {eduPointChat,filmChat} from "@/api/chat";

export default {
  name: "ChatBox",
  data: () => ({
    activeGuide: false,
    messages: [],
    textMessage: '',
    addGuideMessage: false,
    calculateHeight: '40px',
    response_is_finished : true,
    intervalReference : null,
    showGuidMessage : true

  }),
  props:{
    isNewChat:{},
    typeOfShow:{}
  },
  methods: {
    ...mapActions({
      fetchSampleQuesFilm : "movieWave/fetchSampleQuesFilm",
      fetchSampleQuesEdu : "movieWave/fetchSampleQuesEdu",
      fetchCurrentChatHistory : "Chat/fetchCurrentChatHistory"
    }),



    changeTextFieldValue(value,send){
      console.log(value, send);
      this.textMessage = value?.content
      if(send){
        this.addMessage()
      }
      // this.addMessage()
    },



    async changeGuidance() {
      this.activeGuide = !this.activeGuide
      if (this.activeGuide) {
        if(this.getCurrentChat?.item_type === 'EduPoint')
        {
          try {
              await this.fetchSampleQuesEdu(this.getCurrentChat?.item_id)
          }catch (e) {
            console.log(e);
          }
        }
        else {
          try {
            await this.fetchSampleQuesFilm(this.getCurrentChat?.item_id)
          }catch (e) {
            console.log(e);
          }
        }

        this.addGuideMessage = true
        let object = {
          owner: "server",
          is_guide: true
        }
        this.messages.push(object)
        this.showGuidMessage = true
        // this.changeTextFieldValue(this.getCurrentChat.item_type==='EduPoint' ? this.getSampleQuesEdu[0] : this.getSampleQuesFilm[0],false)
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
      else {
        this.addGuideMessage = false
        this.clearizeMessageArray()
        this.showGuidMessage = false
        this.textMessage = ''
      }
    },

    clearizeMessageArray(){

      this.messages = this.messages.filter((message)=>message.is_guide !== true)

    },
    async addMessage() {
      document.getElementById("chatBotTextField").blur()

      this.showGuidMessage = false
      if (this.textMessage !== '' && this.response_is_finished) {
        let response = ''
        this.messages.push({
          owner : "user",
          content : this.textMessage
        })

        if(this.getCurrentChat?.item_type === "EduPoint"){
          let object = {
            eduPoint_id : this.getCurrentChat?.item_id,
            prompt: this.textMessage
          }
          this.textMessage = ''
          response = await eduPointChat(object)
          this.$emit("getListChat")
        }
        else{
          let object = {
            film_id : this.getCurrentChat?.item_id,
            prompt: this.textMessage,
            video_timestamp : this.secondsToHms(this.getCurrentTime !== null ? this.getCurrentTime.time : 0)
          }
          this.textMessage = ''
          response = await filmChat(object)
        }

        this.addGuideMessage = false
        this.$nextTick(() => {
          this.scrollToBottom()
        })

        let answer = {
          content: '',
          owner: "server"
        };
        this.messages.push(answer);
        this.response_is_finished = false

        let splited_text = response.data.response; // Assuming you want to split the first response only
        let index = 0;

        this.intervalReference = setInterval(() => {
          if (index < splited_text.length) {
            if(this.messages[this.messages.length - 1] !== undefined){
              this.messages[this.messages.length - 1].content += splited_text[index];
              index++;
              this.$nextTick(() => {
                this.scrollToBottom();
              });
            }
            else{
              console.log("here");
            }

          } else {
            this.response_is_finished = true
            clearInterval(this.intervalReference); // Stop the interval once all letters are appended
          }
        }, 10); // Append each letter every second

        this.activeGuide = false;
      }
    },
    scrollToBottom() {
      const container = this.$refs.messageContainer
      container.scrollTop = container.scrollHeight
    },
    secondsToHms(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    },
    async fetchDataAndInitialize(newValue){
      this.messages = []
      this.textMessage = ''
      this.activeGuide = false
      clearInterval(this.intervalReference)
      this.response_is_finished = true
      if(this.isNewChat && newValue.item_type !== 'film'){


        await this.fetchSampleQuesEdu(newValue.item_id)
        this.changeTextFieldValue(this.getSampleQuesEdu[0],false)
      }
      if(newValue && !this.isNewChat){
        try{
          await this.fetchCurrentChatHistory(newValue)

        }
        catch (e) {
          console.log(e);
        }
      }
    }
  },
  watch:{
    allMessagesComputed(newValue){
      if(newValue.length !==0){
        this.messages = newValue
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },

    async getCurrentChat(newValue){
      await this.fetchDataAndInitialize(newValue)
    },


  },
  computed:{
    ...mapGetters({
      getCurrentChat : "Chat/getCurrentChat",
      getCurrentChatHistory : "Chat/getCurrentChatHistory",
      getSampleQuesFilm : "movieWave/getSampleQuesFilm",
      getSampleQuesEdu : "movieWave/getSampleQuesEdu",
      getCurrentTime : "setting/getCurrentTime"
    }),
    allMessagesComputed(){
      if(this.getCurrentChatHistory?.results){
        return this.getCurrentChatHistory.results
      }
      return []
    }
  },
  async mounted() {
    let doc = document.getElementById('chatBox');
    let h = doc.offsetHeight;
    this.calculateHeight = (h - 48) + 'px';
    doc.style.gridTemplateRows = `${this.calculateHeight} 48px`;
    if(this.typeOfShow === 1 || this.typeOfShow === 0){
      await this.fetchDataAndInitialize(this.getCurrentChat)
    }
  }
}
</script>

<style scoped>
/* Add any additional styles here */
</style>
